import React from "react";

export const Dote = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={5}
      height={5}
      viewBox="0 0 5 5"
      fill="none"
    >
      <circle cx="2.5" cy="2.5" r="2.5" fill="#FF7D34" />
    </svg>
  );
};

export const Staked = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="{24}"
      height="{26}"
      viewBox="0 0 24 26"
      fill="none"
    >
      <path
        d="M14.504 12.5H22.504C22.504 12.5 23.004 12.5 23.004 13V15C23.004 15 23.004 15.5 22.504 15.5H14.504C14.504 15.5 14.004 15.5 14.004 15V13C14.004 13 14.004 12.5 14.504 12.5Z"
        stroke="#E5E5E5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.504 15.5H21.504C21.504 15.5 22.004 15.5 22.004 16V18C22.004 18 22.004 18.5 21.504 18.5H13.504C13.504 18.5 13.004 18.5 13.004 18V16C13.004 16 13.004 15.5 13.504 15.5Z"
        stroke="#E5E5E5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.504 18.5H22.504C22.504 18.5 23.004 18.5 23.004 19V21C23.004 21 23.004 21.5 22.504 21.5H14.504C14.504 21.5 14.004 21.5 14.004 21V19C14.004 19 14.004 18.5 14.504 18.5Z"
        stroke="#E5E5E5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.504 21.5H21.504C21.504 21.5 22.004 21.5 22.004 22V24C22.004 24 22.004 24.5 21.504 24.5H13.504C13.504 24.5 13.004 24.5 13.004 24V22C13.004 22 13.004 21.5 13.504 21.5Z"
        stroke="#E5E5E5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.504 18.5H9.504C9.504 18.5 10.004 18.5 10.004 19V21C10.004 21 10.004 21.5 9.504 21.5H1.504C1.504 21.5 1.004 21.5 1.004 21V19C1.004 19 1.004 18.5 1.504 18.5Z"
        stroke="#E5E5E5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.504 21.5H10.504C10.504 21.5 11.004 21.5 11.004 22V24C11.004 24 11.004 24.5 10.504 24.5H2.504C2.504 24.5 2.004 24.5 2.004 24V22C2.004 22 2.004 21.5 2.504 21.5Z"
        stroke="#E5E5E5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.5 14.5L9.871 6.133C10.0448 5.95971 10.2763 5.85641 10.5214 5.84276C10.7665 5.82912 11.008 5.90608 11.2 6.059L14.779 8.922C14.9759 9.07876 15.2246 9.15553 15.4756 9.137C15.7265 9.11846 15.9613 9.00598 16.133 8.822L23 1.5"
        stroke="#E5E5E5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.004 1.5H23.004V8"
        stroke="#E5E5E5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const Validators = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={26}
      height={25}
      viewBox="0 0 26 25"
      fill="none"
    >
      <path
        d="M24.5 18C24.5 19.1867 24.1481 20.3467 23.4888 21.3334C22.8295 22.3201 21.8925 23.0892 20.7961 23.5433C19.6997 23.9974 18.4933 24.1162 17.3295 23.8847C16.1656 23.6532 15.0965 23.0818 14.2574 22.2426C13.4182 21.4035 12.8468 20.3344 12.6153 19.1705C12.3838 18.0067 12.5026 16.8003 12.9567 15.7039C13.4109 14.6075 14.1799 13.6705 15.1666 13.0112C16.1533 12.3519 17.3133 12 18.5 12"
        stroke="#E5E5E5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.5 13.5L19.03 18.969C18.9604 19.0387 18.8778 19.0939 18.7869 19.1316C18.6959 19.1693 18.5984 19.1888 18.5 19.1888C18.4016 19.1888 18.3041 19.1693 18.2131 19.1316C18.1222 19.0939 18.0396 19.0387 17.97 18.969L16.25 17.25"
        stroke="#E5E5E5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.25 5.25101C4.25 6.37818 4.69777 7.45918 5.4948 8.25621C6.29183 9.05324 7.37283 9.50101 8.5 9.50101C9.62717 9.50101 10.7082 9.05324 11.5052 8.25621C12.3022 7.45918 12.75 6.37818 12.75 5.25101C12.75 4.12384 12.3022 3.04283 11.5052 2.2458C10.7082 1.44877 9.62717 1.00101 8.5 1.00101C7.37283 1.00101 6.29183 1.44877 5.4948 2.2458C4.69777 3.04283 4.25 4.12384 4.25 5.25101Z"
        stroke="#E5E5E5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.5 18H1.5C1.49995 16.7431 1.83832 15.5094 2.47957 14.4284C3.12082 13.3474 4.04131 12.459 5.14436 11.8565C6.24742 11.2539 7.49236 10.9595 8.74846 11.0041C10.0046 11.0487 11.2255 11.4307 12.283 12.11"
        stroke="#E5E5E5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const APY = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clipPath="url(#clip0_205_6963)">
        <path
          d="M13.5 8.5H11.358C10.9405 8.50015 10.5352 8.64124 10.2078 8.90042C9.88047 9.1596 9.65018 9.5217 9.55426 9.92807C9.45833 10.3344 9.50239 10.7613 9.6793 11.1395C9.85621 11.5177 10.1556 11.8251 10.529 12.012L12.479 12.988C12.8524 13.1749 13.1518 13.4823 13.3287 13.8605C13.5056 14.2387 13.5497 14.6656 13.4537 15.0719C13.3578 15.4783 13.1275 15.8404 12.8002 16.0996C12.4728 16.3588 12.0675 16.4998 11.65 16.5H9.5"
          stroke="#E5E5E5"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.504 8.5V7"
          stroke="#E5E5E5"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.504 18V16.5"
          stroke="#E5E5E5"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.5 12C23.4988 11.1987 23.257 10.4163 22.8059 9.75413C22.3548 9.09192 21.7152 8.58045 20.97 8.286C21.2915 7.55112 21.3839 6.73638 21.2351 5.94818C21.0864 5.15998 20.7034 4.43495 20.1362 3.86777C19.5691 3.30059 18.844 2.91762 18.0558 2.76888C17.2676 2.62014 16.4529 2.71253 15.718 3.034C15.4254 2.28728 14.9145 1.64611 14.2519 1.19415C13.5894 0.742194 12.806 0.500443 12.004 0.500443C11.202 0.500443 10.4186 0.742194 9.75608 1.19415C9.09355 1.64611 8.58263 2.28728 8.29001 3.034C7.55513 2.71253 6.74039 2.62014 5.95219 2.76888C5.16399 2.91762 4.43896 3.30059 3.87178 3.86777C3.3046 4.43495 2.92163 5.15998 2.77289 5.94818C2.62415 6.73638 2.71654 7.55112 3.03801 8.286C2.29129 8.57862 1.65012 9.08954 1.19816 9.75207C0.746207 10.4146 0.504456 11.198 0.504456 12C0.504456 12.802 0.746207 13.5854 1.19816 14.2479C1.65012 14.9105 2.29129 15.4214 3.03801 15.714C2.71654 16.4489 2.62415 17.2636 2.77289 18.0518C2.92163 18.84 3.3046 19.565 3.87178 20.1322C4.43896 20.6994 5.16399 21.0824 5.95219 21.2311C6.74039 21.3799 7.55513 21.2875 8.29001 20.966C8.58263 21.7127 9.09355 22.3539 9.75608 22.8058C10.4186 23.2578 11.202 23.4995 12.004 23.4995C12.806 23.4995 13.5894 23.2578 14.2519 22.8058C14.9145 22.3539 15.4254 21.7127 15.718 20.966C16.4529 21.2875 17.2676 21.3799 18.0558 21.2311C18.844 21.0824 19.5691 20.6994 20.1362 20.1322C20.7034 19.565 21.0864 18.84 21.2351 18.0518C21.3839 17.2636 21.2915 16.4489 20.97 15.714C21.7152 15.4195 22.3548 14.9081 22.8059 14.2459C23.257 13.5837 23.4988 12.8012 23.5 12Z"
          stroke="#E5E5E5"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_205_6963">
          <rect width={24} height={24} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

// Moon Icons

export const NewMoon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={130}
      height={130}
      viewBox="0 0 130 130"
      fill="none"
    >
      <path
        d="M128.65 64.7082C128.65 99.8916 100.076 128.416 64.825 128.416C29.5737 128.416 1 99.8916 1 64.7082C1 29.5248 29.5737 1 64.825 1C100.076 1 128.65 29.5248 128.65 64.7082Z"
        fill="white"
        fillOpacity="0.1"
        stroke="#737373"
        strokeWidth={2}
      />
    </svg>
  );
};

export const WaxingCrescent = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={131}
      height={130}
      viewBox="0 0 131 130"
      fill="none"
    >
      <circle
        cx="65.3248"
        cy="64.7082"
        r="63.7082"
        fill="white"
        fillOpacity="0.1"
        stroke="#737373"
        strokeWidth={2}
      />
    </svg>
  );
};

export const FirstQuarter = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={130}
      height={130}
      viewBox="0 0 130 130"
      fill="none"
    >
      <circle
        cx="64.9459"
        cy="64.7082"
        r="63.7082"
        fill="white"
        fillOpacity="0.1"
        stroke="#737373"
        strokeWidth={2}
      />
    </svg>
  );
};

export const FirstWaningGibbous = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={131}
      height={130}
      viewBox="0 0 131 130"
      fill="none"
    >
      <circle
        cx="65.3289"
        cy="64.7082"
        r="63.7082"
        fill="white"
        fillOpacity="0.1"
        stroke="#737373"
        strokeWidth={2}
      />
    </svg>
  );
};

export const WaningGibbous = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={131}
      height={130}
      viewBox="0 0 131 130"
      fill="none"
    >
      <circle
        cx="65.5714"
        cy="64.7082"
        r="63.7082"
        fill="white"
        fillOpacity="0.1"
        stroke="#737373"
        strokeWidth={2}
      />
    </svg>
  );
};

export const LastQuarter = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={130}
      height={130}
      viewBox="0 0 130 130"
      fill="none"
    >
      <circle
        cx="64.7244"
        cy="64.7082"
        r="63.7082"
        fill="white"
        fillOpacity="0.1"
        stroke="#737373"
        strokeWidth={2}
      />
    </svg>
  );
};

export const LastWaxingCrescent = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={130}
      height={130}
      viewBox="0 0 130 130"
      fill="none"
    >
      <circle
        cx="65.1073"
        cy="64.7082"
        r="63.7082"
        fill="white"
        fillOpacity="0.1"
        stroke="#737373"
        strokeWidth={2}
      />
    </svg>
  );
};

export const LeftArrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={8}
      height={11}
      viewBox="0 0 8 11"
      fill="none"
    >
      <path
        d="M7 10.4165L2 5.9165L7 1.4165"
        stroke="#E5E5E5"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export const RightArrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={8}
      height={11}
      viewBox="0 0 8 11"
      fill="none"
    >
      <path
        d="M1 10.4165L6 5.9165L1 1.4165"
        stroke="#E5E5E5"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export const Close = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={10}
      height={10}
      viewBox="0 0 8 8"
      fill="none"
    >
      <path
        d="M7 1L4 4M1 7L4 4M4 4L7 7M4 4L1 1"
        stroke="#E5E5E5"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export const ArrowLine = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={21}
      height={2}
      viewBox="0 0 21 2"
      fill="none"
    >
      <path d="M0.616577 1.41638H20.9438" stroke="#737373" />
    </svg>
  );
};

export const ArrowRight = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={22}
      height={9}
      viewBox="0 0 22 9"
      fill="none"
    >
      <path
        d="M21.6246 4.76994C21.8199 4.57467 21.8199 4.25809 21.6246 4.06283L18.4426 0.880848C18.2474 0.685586 17.9308 0.685586 17.7355 0.880848C17.5403 1.07611 17.5403 1.39269 17.7355 1.58795L20.5639 4.41638L17.7355 7.24481C17.5403 7.44007 17.5403 7.75665 17.7355 7.95192C17.9308 8.14718 18.2474 8.14718 18.4426 7.95192L21.6246 4.76994ZM0.943848 4.91638H21.2711V3.91638H0.943848V4.91638Z"
        fill="#737373"
      />
    </svg>
  );
};

export const ArrowGreen = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={15}
      height={9}
      viewBox="0 0 15 9"
      fill="none"
    >
      <path
        d="M14.6289 4.76994C14.8241 4.57467 14.8241 4.25809 14.6289 4.06283L11.4469 0.880848C11.2516 0.685586 10.935 0.685586 10.7398 0.880848C10.5445 1.07611 10.5445 1.39269 10.7398 1.58795L13.5682 4.41638L10.7398 7.24481C10.5445 7.44007 10.5445 7.75665 10.7398 7.95192C10.935 8.14718 11.2516 8.14718 11.4469 7.95192L14.6289 4.76994ZM0.620728 4.91638H14.2753V3.91638H0.620728V4.91638Z"
        fill="#53EAA1"
      />
    </svg>
  );
};

export const ArrowDown = () => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={10}
    height={7}
    viewBox="0 0 10 7"
    fill="none"
  >
    <path d="M9 1L5 5L1 1" stroke="#152024" strokeWidth="1.5" />
  </svg>
  );
};

export const CopyIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={11}
      height={12}
      viewBox="0 0 11 12"
      fill="none"
    >
      <rect
        x="3.80005"
        y="3.5"
        width="6.7"
        height={8}
        fill="#152024"
        stroke="#737373"
      />
      <rect
        x="0.5"
        y="0.5"
        width="6.7"
        height={8}
        fill="#152024"
        stroke="#737373"
      />
    </svg>
  );
};

export const ArrowUp = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={13}
      height={8}
      viewBox="0 0 13 8"
      fill="none"
    >
      <path
        d="M12.3536 4.35355C12.5488 4.15829 12.5488 3.84171 12.3536 3.64645L9.17157 0.464466C8.97631 0.269204 8.65973 0.269204 8.46447 0.464466C8.2692 0.659728 8.2692 0.976311 8.46447 1.17157L11.2929 4L8.46447 6.82843C8.2692 7.02369 8.2692 7.34027 8.46447 7.53553C8.65973 7.7308 8.97631 7.7308 9.17157 7.53553L12.3536 4.35355ZM0.5 0V2H1.5V0H0.5ZM3 4.5H12V3.5H3V4.5ZM0.5 2C0.5 3.38071 1.61929 4.5 3 4.5V3.5C2.17157 3.5 1.5 2.82843 1.5 2H0.5Z"
        fill="#FF7D34"
      />
    </svg>
  );
};
