//TEST CONTRACT 0x5799E8fc8fcd6f07a1E282F21C4e8432AaC0ee1f
// export const CONTRACT_ADDRESS = '0x2543a1BbE1dcb3192Dc00ed046f3d1146f805915';

//BETA CONTRACT ADDRESS
export const CONTRACT_ADDRESS = "0x2543a1BbE1dcb3192Dc00ed046f3d1146f805915";

export const CONTRACT_ADDRESS_MAIN =
  "0x3A1A1a46d2Ae1F50edEe56fEfE7385F9c71960fA";

export const TOKEN_CONTRACT_ADDRESS =
  "0x2543a1BbE1dcb3192Dc00ed046f3d1146f805915";

export const VALIDATOR_CONTRACT_ADDRESS_BETA =
  "0xF5f3B5DB0Eb0e057F8e8694Fca0909E2e7dd3960";

// TEST : 3012 , BETA : '4321'
export const CHAIN_ID = 4321;

export const CHAIN_NAME = "LYCAN-BETA";

export const SYMBOL = "BLYC";
export const DECIMALS = 18;

export const BST_PRICE = 0.5;

export const RPC = "https://rpc-beta.lycanchain.com";

export const ROOT_URL = "http://18.117.41.254:8545";
export const CONFIRMATIONS = 6;
export const FIX_DECIMALS = 6;

export const EXPLORER_SUBDOMAIN = "explorer-beta";

export const RPC_URLS_DEFAULT = [
  "https://rpc.lycanchain.com",
  "https://us-east.lycanchain.com",
  "https://us-west.lycanchain.com",
  "https://eu-north.lycanchain.com",
  "https://eu-west.lycanchain.com",
  "https://asia-southeast.lycanchain.com",
];

export const RPC_URLS_WEBSOCKET = [
  "wss://rpc.lycanchain.com",
  "wss://us-east.lycanchain.com",
  "wss://us-west.lycanchain.com",
  "wss://eu-north.lycanchain.com",
  "wss://eu-west.lycanchain.com",
  "wss://asia-southeast.lycanchain.com",
];

export const BLOCK_EXPLORER = {
  name: "Lycan Explorer",
  url: "https://explorer.lycanchain.com",
};
