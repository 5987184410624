import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";

import { createWeb3Modal, useWeb3ModalTheme } from "@web3modal/wagmi/react";

import { WagmiProvider } from "wagmi";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import config from "./component/_providers/config";

// 0. Setup queryClient
const queryClient = new QueryClient();

// 1. Your WalletConnect Cloud project ID
const projectId = "05bf1a3bdce78ac897ccc68fafd1c7af";

const myWallets = [
  "c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96",
  "622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0",
];

// 3. Create modal
createWeb3Modal({
  wagmiConfig: config,
  projectId,
  includeWalletIds: myWallets,
  enableAnalytics: true, // Optional - defaults to your Cloud configuration
  enableOnramp: false, // Optional - false as default
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <WagmiProvider config={config}>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </QueryClientProvider>
  </WagmiProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
