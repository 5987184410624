import React, { useEffect, useState } from "react";
import Header from "../component/Header";

import WolfPfp from "../assets/wolf_pfp.jpg";

import {
  useAccount,
  useBalance,
  useConfig,
  usePublicClient,
  useWatchContractEvent,
} from "wagmi";

import {
  APY,
  ArrowGreen,
  ArrowLine,
  ArrowRight,
  Dote,
  FirstQuarter,
  LeftArrow,
  NewMoon,
  RightArrow,
  Staked,
  Validators,
  WaxingCrescent,
} from "../component/icons";
import { Link, useNavigate } from "react-router-dom";
import Delegate from "../component/modals/Delegate";
import {
  useContract,
  useStakingAllowed,
  useValidatorContract,

  // usePoolDetails,
  // useStakingContract,
} from "../hooks/useStakingContract";

import ABI from "../abi/staking_aura.json";
import { CONTRACT_ADDRESS, CONTRACT_ADDRESS_MAIN } from "../constants";

import { convertDividePrice } from "../utils/commonFunctions";

import { shortenAddress } from "../utils";

import config from "../component/_providers/config";
import Validate from "../component/modals/Validate";

import MoonPhases from "../component/MoonPhases";
import Web3 from "web3";
import { watchContractEvent } from "wagmi/actions";
import { useGetPoolName } from "../hooks/PoolUtils";

export default function LycanchainStaking() {
  const navigate = useNavigate();

  const config = useConfig();

  const publicClient = usePublicClient();

  // useWatchContractEvent({
  //   address: process.env.REACT_APP_CONTRACT_ADDRESS,
  //   ABI,
  //   eventName: "AddedPool",

  //   onLogs(logs) {
  //     console.log("New logs!", logs);
  //   },
  //   onError(error) {
  //     console.log("Error", error);
  //   },
  // });

  // const getEvents = async () => {
  //   const logs = await publicClient.getContractEvents({
  //     address: process.env.REACT_APP_CONTRACT_ADDRESS,
  //     abi: ABI,
  //     eventName: "AddedPool",
  //     // args: {
  //     //   from: '0xd8da6bf26964af9d7eed9e03e53415d37aa96045',
  //     //   to: '0xa5cc3c03994db5b0d9a5eedd10cabab0813678ac'
  //     // },
  //     // fromBlock: 16330000n,
  //     // toBlock: 16330050n
  //   });

  //   console.log("events : ", logs);
  // };

  let { stakingAllowed } = useStakingAllowed();

  let { address } = useAccount();

  let { data, isError, error } = useBalance({
    address: CONTRACT_ADDRESS_MAIN,
  });

  const result = useBalance({
    address: CONTRACT_ADDRESS_MAIN,
  });

  const [delegatorMinStake, setDelegatorMinStake] = useState("");
  const [validatorMinStake, setvalidatorMinStake] = useState("");

  const [currentBalance, setcurrentBalance] = useState(null);

  const [contractBalance, setContractBalance] = useState(null);

  const contract = useContract();
  const validatorContract = useValidatorContract();

  const [show, setShow] = useState(false);
  const [showValidate, setShowValidate] = useState(false);

  const [pools, setpools] = useState([]);
  const [poolDetails, setpoolDetails] = useState([]);
  const [poolNames, setPoolNames] = useState([]);

  const [selectedPool, setselectedPool] = useState(null);

  const handleClose = () => setShow(false);
  const handleValidateClose = () => {
    setShowValidate(false);
  };

  const [delegatorList, setdelegatorList] = useState([]);
  const [currentDelegatorItems, setcurrentDelegatorItems] = useState([]);

  const [topDelegatorList, settopDelegatorsList] = useState([]);
  const [currentTopDelegatorListItems, setCurrentTopDelegatorsListItems] =
    useState([]);

  const [itemsPerPage, setitemsPerPage] = useState(10);
  const [currentItems, setcurrentItems] = useState([]);
  const [totalPages, settotalPages] = useState(1);

  const [currentPage, setcurrentPage] = useState(1);

  const calculateCurrentItems = () => {
    // console.log("calculate called");
    // Calculate the current items to display
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    setcurrentItems(poolDetails.slice(indexOfFirstItem, indexOfLastItem));

    settotalPages(Math.ceil(pools.length / itemsPerPage));
  };

  const handleNext = () => {
    if (currentPage === totalPages) {
      return;
    } else {
      setcurrentPage(currentPage + 1);
    }
  };

  const handlePrev = () => {
    if (currentPage === 1) {
      setcurrentPage(1);
    } else {
      setcurrentPage(currentPage - 1);
    }
  };

  useEffect(() => {
    calculateCurrentItems();
  }, [pools, itemsPerPage, currentPage]);

  const paginate = (pageNumber) => setcurrentPage(pageNumber);

  const handleDelegate = (poolAddress) => {
    if (address) {
      setselectedPool(poolAddress);
      setShow(true);
    } else {
      alert("Please connect wallet");
    }
  };

  const getDelegatorMinStake = async () => {
    contract.read
      .delegatorMinStake()
      .then((value) => {
        if (value) {
          setDelegatorMinStake(value);
        }
      })
      .catch((error) => {
        console.error("useStaking pools error: ", error);
      });
  };

  const getValidatorMinStake = async () => {
    contract.read
      .candidateMinStake()
      .then((value) => {
        if (value) {
          setvalidatorMinStake(value);
        }
      })
      .catch((error) => {
        console.error("useStaking pools error: ", error);
      });
  };

  const onValidate = () => {
    if (address) {
      setShowValidate(true);
    } else {
      alert("please connect wallet");
    }
  };

  const getPools = () => {
    contract.read
      .getPools()
      .then(async (value) => {
        if (value) {
          // setPoolIds(value.map((pool) => pool.toString()));
          setpools(value.map((pool) => Web3.utils.toHex(pool)));
          // console.log({ pools });
          const poolInfoListPromises = pools.map(async (pool) => {
            const value = await validatorContract.read.poolName([pool]);
            const name = value ? value : `Alpha ${pool.slice(-2)}`;
            return { name, address: pool };
          });
          const poolInfoList = await Promise.all(poolInfoListPromises);
          setpoolDetails(poolInfoList);
        }
      })
      .catch((error) => {
        console.error("useStaking pools error: ", error);
      });
  };

  useEffect(() => {
    getPools();
  }, [address, contract, poolDetails]);

  useEffect(() => {
    if (address) {
      getDelegatorMinStake();
      getValidatorMinStake();
    }
  }, [address]);

  const handleClick = (poolId) => {
    navigate(`/validators-details/${poolId}`);
  };

  useEffect(() => {
    if (data) {
      if (convertDividePrice(data.value)) {
        setContractBalance(convertDividePrice(data.value));
      } else {
        setcurrentBalance(0.0);
      }

      if (error) {
        console.log("get contract balance : ", error);
      }
    }
  }, [data, error, isError]);

  const getPoolName = () => {};

  return (
    <>
      <Header />
      <div className="layout">
        <div className="container">
          <div className="main">
            <div className="row">
              <div className="col-lg-8">
                <div className="d-flex flex-column justify-content-between gap-lg-0 gap-4 h-100">
                  {/* <p className="text-para text-white">
                    current epoch : {currentEpoch.toString()}
                  </p> */}
                  {/* <p className="text-para text-white">
                    next epoch in : {nextEpoch.toString()}
                  </p> */}
                  <div className="semi_title">
                    <Dote />
                    <span>Mainnet</span>
                  </div>
                  <div>
                    <h1 className="main_title">
                      Lycan Chain <span className="text_brand">Staking</span>
                    </h1>

                    <p className="text_pera mb-3">
                      By Staking LYC, you can contribute to LYC network security
                      & earn Rewards.
                    </p>
                    <button
                      onClick={onValidate}
                      className="btn wallet_btn bg-white"
                    >
                      Become Validator
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 mt-lg-0 mt-4">
                <div className="payment_card mb_10">
                  <div className="d-flex flex-column justify-content-between gap-2">
                    <p className="card_title">Total LYC Staked</p>
                    <h5 className="card_price">{contractBalance} LYC</h5>
                  </div>
                  <div className="icon_card">
                    <Staked />
                  </div>
                </div>
                <div className="payment_card mb_10">
                  <div className="d-flex flex-column justify-content-between gap-2">
                    <p className="card_title">Validators</p>
                    <h5 className="card_price">{pools.length}/60</h5>
                  </div>
                  <div className="icon_card">
                    <Validators />
                  </div>
                </div>
                <div className="payment_card">
                  <div className="d-flex flex-column justify-content-between gap-2">
                    <p className="card_title">APY</p>
                    <h5 className="card_price">Up to 2.5%</h5>
                  </div>
                  <div className="icon_card">
                    <APY />
                  </div>
                </div>
              </div>
            </div>

            <div className="hr_border"></div>
            <MoonPhases />
            <div className="hr_border"></div>
            <div className="">
              {/* <h2 className="second_title">Validators</h2> */}
              <ul
                className="nav nav-pills align-items-center gap-md-2 gap-1 mb-3"
                id="pills-tab"
                role="tablist"
              >
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link history_btn active"
                    id="pills-validators-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-validators"
                    type="button"
                    role="tab"
                    aria-controls="pills-validators"
                    aria-selected="true"
                  >
                    All Validators
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link history_btn"
                    id="pills-delegators-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-delegators"
                    type="button"
                    role="tab"
                    aria-controls="pills-delegators"
                    aria-selected="false"
                  >
                    All Delegators
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link history_btn"
                    id="top-delegators-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#top-delegators"
                    type="button"
                    role="tab"
                    aria-controls="top-delegators"
                    aria-selected="false"
                  >
                    Top Delegators
                  </button>
                </li>
              </ul>
              <div className="tab-content" id="pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="pills-validators"
                  role="tabpanel"
                  aria-labelledby="pills-validators-tab"
                  tabindex="{0}"
                >
                  {poolDetails.length === 0 ? (
                    <p class="text-center text-white">VALIDATORS EMPTY</p>
                  ) : (
                    <div className="table-responsive">
                      <table className="table validator_table border-0 mb-0">
                        <thead>
                          <tr>
                            <th>
                              Validator
                              <span className="ms-1">
                                <img src="/assets/img/icon/alert.svg" />
                              </span>
                            </th>
                            <th>
                              Total LYC Staked
                              <span className="ms-1">
                                <img src="/assets/img/icon/alert.svg" />
                              </span>
                            </th>
                            <th>
                              Delegators
                              <span className="ms-1">
                                <img src="/assets/img/icon/alert.svg" />
                              </span>
                            </th>
                            <th>
                              APY
                              <span className="ms-1">
                                <img src="/assets/img/icon/alert.svg" />
                              </span>
                            </th>
                            <th className="text-start" colSpan={2}>
                              Status
                              <span className="ms-1">
                                <img src="/assets/img/icon/alert.svg" />
                              </span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentItems.map((item, i) => (
                            <ValidatorItem
                              key={i}
                              name={item.name}
                              // name={`Alpha ${item.slice(-2)}`}
                              i={i + 1}
                              address={item.address}
                              currentPage={currentPage}
                              onDelegate={() => handleDelegate(item.address)}
                              onClick={() => {
                                handleClick(item.address);
                              }}
                            />
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}

                  <div className="table_footer">
                    <div className="d-flex flex-md-row flex-column align-items-md-center gap-2">
                      <p className="footer_title">Row per Page</p>
                      <select
                        className="form-select page_select"
                        value={itemsPerPage}
                        onChange={(e) => {
                          console.log(e.target.value);
                          setitemsPerPage(e.target.value);
                        }}
                      >
                        <option value={10}>10</option>
                        <option value={15}>15</option>
                        <option value={20}>20</option>
                      </select>
                    </div>
                    <div className="d-flex flex-md-row flex-column align-items-md-center gap-2">
                      <p className="footer_title">
                        Total of {pools.length} Validators
                      </p>
                      <ul className="table_pagination">
                        <li onClick={handlePrev}>
                          <Link>
                            <LeftArrow />
                          </Link>
                        </li>
                        {Array.from({ length: totalPages }, (_, index) => (
                          <li onClick={() => setcurrentPage(index + 1)}>
                            <Link className="">{index + 1}</Link>
                          </li>
                        ))}

                        <li onClick={handleNext}>
                          <Link>
                            <RightArrow />
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade show"
                  id="pills-delegators"
                  role="tabpanel"
                  aria-labelledby="pills-delegators-tab"
                  tabindex="{0}"
                >
                  {delegatorList.length === 0 ? (
                    <p class="text-center text-white">DELEGATORS EMPTY</p>
                  ) : (
                    <div className="table-responsive">
                      <table className="table validator_table border-0 mb-0">
                        <thead>
                          <tr>
                            <th>
                              Delegator
                              <span className="ms-1">
                                <img src="/assets/img/icon/alert.svg" />
                              </span>
                            </th>
                            <th>
                              Total LYC Staked
                              <span className="ms-1">
                                <img src="/assets/img/icon/alert.svg" />
                              </span>
                            </th>
                            <th>
                              Commission
                              <span className="ms-1">
                                <img src="/assets/img/icon/alert.svg" />
                              </span>
                            </th>
                            <th>
                              APY
                              <span className="ms-1">
                                <img src="/assets/img/icon/alert.svg" />
                              </span>
                            </th>
                            <th className="text-start" colSpan={2}>
                              Status
                              <span className="ms-1">
                                <img src="/assets/img/icon/alert.svg" />
                              </span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {/* DELEGATOR ITEM BODY */}
                          {currentDelegatorItems.map((item, i) => (
                            <DelegatorItem
                              key={i}
                              name={item.name}
                              // name={`Alpha ${item.slice(-2)}`}
                              i={i + 1}
                              address={item.address}
                              currentPage={currentPage}
                              onDelegate={() => handleDelegate(item.address)}
                              onClick={() => {
                                handleClick(item.address);
                              }}
                            />
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}

                  <div className="table_footer">
                    <div className="d-flex flex-md-row flex-column align-items-md-center gap-2">
                      <p className="footer_title">Row per Page</p>
                      <select
                        className="form-select page_select"
                        value={itemsPerPage}
                        onChange={(e) => {
                          console.log(e.target.value);
                          setitemsPerPage(e.target.value);
                        }}
                      >
                        <option value={10}>10</option>
                        <option value={15}>15</option>
                        <option value={20}>20</option>
                      </select>
                    </div>
                    <div className="d-flex flex-md-row flex-column align-items-md-center gap-2">
                      <p className="footer_title">
                        Total of {pools.length} Validators
                      </p>
                      <ul className="table_pagination">
                        <li>
                          <Link>
                            <LeftArrow />
                          </Link>
                        </li>
                        <li>
                          <Link className="active">1</Link>
                        </li>
                        <li>
                          <Link>2</Link>
                        </li>
                        <li>
                          <Link>3</Link>
                        </li>
                        <li>
                          <Link>
                            <RightArrow />
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade show"
                  id="top-delegators"
                  role="tabpanel"
                  aria-labelledby="top-delegators-tab"
                  tabindex="{0}"
                >
                  {topDelegatorList.length === 0 ? (
                    <p class="text-center text-white">TOP DELEGATORS EMPTY</p>
                  ) : (
                    <div className="table-responsive">
                      <table className="table validator_table border-0 mb-0">
                        <thead>
                          <tr>
                            <th>
                              Validator
                              <span className="ms-1">
                                <img src="/assets/img/icon/alert.svg" />
                              </span>
                            </th>
                            <th>
                              Total LYC Staked
                              <span className="ms-1">
                                <img src="/assets/img/icon/alert.svg" />
                              </span>
                            </th>
                            <th>
                              Commission
                              <span className="ms-1">
                                <img src="/assets/img/icon/alert.svg" />
                              </span>
                            </th>
                            <th>
                              APY
                              <span className="ms-1">
                                <img src="/assets/img/icon/alert.svg" />
                              </span>
                            </th>
                            <th className="text-start" colSpan={2}>
                              Status
                              <span className="ms-1">
                                <img src="/assets/img/icon/alert.svg" />
                              </span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <div className="table_data">
                                <img src="/assets/img/user/user1.png" />
                                <span className="user_data">Legendill</span>
                                <span className="position">Cabinet</span>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex gap-2 align-items-center">
                                <span className="user_data">
                                  1525125.235512512
                                </span>{" "}
                                <span className="user_data">LYC</span>
                                <span className="rank">6.28%</span>
                              </div>
                            </td>
                            <td>7.00%</td>
                            <td>0.56%</td>
                            <td>
                              <div className="d-flex align-items-center gap-1">
                                <span className="dote"></span>
                                <span className="text_green">Active</span>
                              </div>
                            </td>
                            <td className="text-end">
                              <button
                                className="btn table_btn"
                                onClick={() => setShow(true)}
                              >
                                Delegate
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className="table_data">
                                <img src="/assets/img/user/user1.png" />
                                <span className="user_data">Astral</span>
                                <span className="position">Candidate</span>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex gap-2 align-items-center">
                                <span className="user_data">
                                  1,425,225,325612214
                                </span>{" "}
                                <span className="user_data">LYC</span>
                                <span className="rank">8.03%</span>
                              </div>
                            </td>
                            <td>9.10%</td>
                            <td>0.48%</td>
                            <td>
                              <div className="d-flex align-items-center gap-1">
                                <span className="dote bg_danger"></span>
                                <span className="text_danger text-uppercase">
                                  INACTIVE
                                </span>
                              </div>
                            </td>
                            <td className="text-end">
                              <button className="btn table_btn">
                                Delegate
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className="table_data">
                                <img src="/assets/img/user/user1.png" />
                                <span className="user_data">Legendill</span>
                                <span className="position">Cabinet</span>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex gap-2 align-items-center">
                                <span className="user_data">
                                  1525125.235512512
                                </span>{" "}
                                <span className="user_data">LYC</span>
                                <span className="rank">6.28%</span>
                              </div>
                            </td>
                            <td>7.00%</td>
                            <td>0.56%</td>
                            <td>
                              <div className="d-flex align-items-center gap-1">
                                <span className="dote"></span>
                                <span className="text_green text-uppercase">
                                  Active
                                </span>
                              </div>
                            </td>
                            <td className="text-end">
                              <button className="btn table_btn">
                                Delegate
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className="table_data">
                                <img src="/assets/img/user/user1.png" />
                                <span className="user_data">Legendill</span>
                                <span className="position">Cabinet</span>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex gap-2 align-items-center">
                                <span className="user_data">
                                  1525125.235512512
                                </span>{" "}
                                <span className="user_data">LYC</span>
                                <span className="rank">6.28%</span>
                              </div>
                            </td>
                            <td>7.00%</td>
                            <td>0.56%</td>
                            <td>
                              <div className="d-flex align-items-center gap-1">
                                <span className="dote"></span>
                                <span className="text_green text-uppercase">
                                  Active
                                </span>
                              </div>
                            </td>
                            <td className="text-end">
                              <button className="btn table_btn">
                                Delegate
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className="table_data">
                                <img src="/assets/img/user/user1.png" />
                                <span className="user_data">Legendill</span>
                                <span className="position">Cabinet</span>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex gap-2 align-items-center">
                                <span className="user_data">
                                  1525125.235512512
                                </span>{" "}
                                <span className="user_data">LYC</span>
                                <span className="rank">6.28%</span>
                              </div>
                            </td>
                            <td>7.00%</td>
                            <td>0.56%</td>
                            <td>
                              <div className="d-flex align-items-center gap-1">
                                <span className="dote"></span>
                                <span className="text_green text-uppercase">
                                  Active
                                </span>
                              </div>
                            </td>
                            <td className="text-end">
                              <button className="btn table_btn">
                                Delegate
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className="table_data">
                                <img src="/assets/img/user/user1.png" />
                                <span className="user_data">Legendill</span>
                                <span className="position">Cabinet</span>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex gap-2 align-items-center">
                                <span className="user_data">
                                  1525125.235512512
                                </span>{" "}
                                <span className="user_data">LYC</span>
                                <span className="rank">6.28%</span>
                              </div>
                            </td>
                            <td>7.00%</td>
                            <td>0.56%</td>
                            <td>
                              <div className="d-flex align-items-center gap-1">
                                <span className="dote"></span>
                                <span className="text_green text-uppercase">
                                  Active
                                </span>
                              </div>
                            </td>
                            <td className="text-end">
                              <button className="btn table_btn">
                                Delegate
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className="table_data">
                                <img src="/assets/img/user/user1.png" />
                                <span className="user_data">Legendill</span>
                                <span className="position">Cabinet</span>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex gap-2 align-items-center">
                                <span className="user_data">
                                  1525125.235512512
                                </span>{" "}
                                <span className="user_data">LYC</span>
                                <span className="rank">6.28%</span>
                              </div>
                            </td>
                            <td>7.00%</td>
                            <td>0.56%</td>
                            <td>
                              <div className="d-flex align-items-center gap-1">
                                <span className="dote"></span>
                                <span className="text_green text-uppercase">
                                  Active
                                </span>
                              </div>
                            </td>
                            <td className="text-end">
                              <button
                                className="btn table_btn"
                                onClick={() => setShow(true)}
                              >
                                Delegate
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}

                  <div className="table_footer">
                    <div className="d-flex flex-md-row flex-column align-items-md-center gap-2">
                      <p className="footer_title">Row per Page</p>
                      <select
                        className="form-select page_select"
                        value={itemsPerPage}
                        onChange={(e) => {
                          console.log(e.target.value);
                          setitemsPerPage(e.target.value);
                        }}
                      >
                        <option value={10}>10</option>
                        <option value={15}>15</option>
                        <option value={20}>20</option>
                      </select>
                    </div>
                    <div className="d-flex flex-md-row flex-column align-items-md-center gap-2">
                      <p className="footer_title">
                        Total of {pools.length} Validators
                      </p>
                      <ul className="table_pagination">
                        <li>
                          <Link>
                            <LeftArrow />
                          </Link>
                        </li>
                        <li>
                          <Link className="active">1</Link>
                        </li>
                        <li>
                          <Link>2</Link>
                        </li>
                        <li>
                          <Link>3</Link>
                        </li>
                        <li>
                          <Link onClick={() => paginate(2)}>
                            <RightArrow />
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <Delegate
                selectedPool={selectedPool}
                show={show}
                minStake={delegatorMinStake}
                handleClose={handleClose}
                balance={currentBalance}
                currentEpoch={""}
                stakingAllowed={stakingAllowed}
              />

              <Validate
                show={showValidate}
                minStake={validatorMinStake}
                handleClose={handleValidateClose}
                balance={currentBalance}
                pools={pools}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const ValidatorItem = ({
  i,
  name,
  address,
  onDelegate,
  onClick,
  currentPage,
}) => {
  const [poolStakeAmt, setpoolStakeAmt] = useState("");

  const [poolName, setPoolName] = useState(null);
  const [poolDesc, setPoolDesc] = useState(null);
  const [poolDelegators, setpoolDelegators] = useState([]);

  const contract = useContract();
  const validatorContract = useValidatorContract();

  function getTotalStakeAmount() {
    contract.read
      .stakeAmountTotal([address])

      .then((value) => {
        // setisLoading(false);
        setpoolStakeAmt(value);
      })
      .catch((error) => {
        // setisLoading(false);
        console.error(error);
      });
  }

  const getPoolInfo = async (address) => {
    await validatorContract.read
      .poolName([address])
      .then((value) => {
        if (value) {
          setPoolName(value);
          console.log("poolName : ", value);
        } else {
          console.log("pool name no value");
        }
      })
      .catch((error) => {
        console.error("pool name error: ", error);
      });

    await validatorContract.read
      .poolDescription([address])
      .then((value) => {
        if (value) {
          setPoolDesc(value);
          console.log("poolName : ", value);
        } else {
          console.log("pool desc no value");
        }
      })
      .catch((error) => {
        console.error("pool desc error: ", error);
      });
  };

  const getPoolDelegators = async () => {
    contract.read
      .poolDelegators([address])

      .then((value) => {
        setpoolDelegators(value);
      })
      .catch((error) => {
        // setisLoading(false);
        console.error(error);
      });
  };

  useEffect(() => {
    getTotalStakeAmount();
    getPoolDelegators();
    // getPoolInfo(address);
  }, [address, poolName]);

  return (
    <tr onClick={onClick} role="button">
      <td>
        <div className="table_data ">
          <div className="relative  rounded-lg overflow-hidden">
            <img
              src={WolfPfp}
              height={20}
              width={20}
              className="rounded"
              alt="validator"
            />
          </div>

          <span className="user_data">{name}</span>
          <span className="position">Validator</span>
        </div>
      </td>
      <td>
        <div className="d-flex gap-2 align-items-center">
          <span className="user_data">
            {parseFloat(convertDividePrice(poolStakeAmt)).toFixed(2)}
          </span>{" "}
          <span className="user_data">LYC</span>
          {/* <span className="rank">6.28%</span> */}
        </div>
      </td>
      <td>{poolDelegators.length}</td>
      <td>2.5%</td>
      <td>
        <div className="d-flex align-items-center gap-1">
          <span className="dote"></span>
          <span className="text_green">Active</span>
        </div>
      </td>
      <td className="text-end" onClick={onDelegate}>
        <button className="btn table_btn" onClick={onDelegate}>
          Delegate
        </button>
      </td>
    </tr>
  );
};

const DelegatorItem = () => {
  return (
    <tr>
      <td>
        <div className="table_data">
          <img src="/assets/img/user/user1.png" />
          <span className="user_data">Legendill</span>
          <span className="position">Cabinet</span>
        </div>
      </td>
      <td>
        <div className="d-flex gap-2 align-items-center">
          <span className="user_data">1525125.235512512</span>{" "}
          <span className="user_data">LYC</span>
          <span className="rank">6.28%</span>
        </div>
      </td>
      <td>7.00%</td>
      <td>2.50%</td>
      <td>
        <div className="d-flex align-items-center gap-1">
          <span className="dote"></span>
          <span className="text_green">Active</span>
        </div>
      </td>
      <td className="text-end">
        <button className="btn table_btn" onClick={() => {}}>
          Delegate
        </button>
      </td>
    </tr>
  );
};
