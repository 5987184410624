// import logo from './logo.svg';
import { Route, Routes } from "react-router-dom";
import "./App.css";
import LycanchainStaking from "./pages/LycanchainStaking";
import ValidatorsDetails from "./pages/ValidatorsDetails";
import MyStaking from "./pages/MyStaking";

function App() {
  return (
    <>
      <div className="App">
        <Routes>
          <Route path="/" element={<LycanchainStaking balance={""} />} />
          <Route
            path="validators-details/:id"
            element={<ValidatorsDetails />}
          />
          <Route path="my-staking" element={<MyStaking />} />
        </Routes>
      </div>
    </>
  );
}

export default App;
