import { Button, Modal } from "react-bootstrap";
import { ArrowDown, Close } from "../icons";

export default function Redelegate({ show, handleClose }) {
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        className="delegate"
        centered
        shouldCloseOnOverlayClick={false}
        backdrop="static"
        keyboard="false"
      >
        <Modal.Header>
          <Modal.Title>Undelegate</Modal.Title>
          <Button className="btn-close" onClick={handleClose}>
            <Close />
          </Button>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="modal_card mb_20">
            <p className="text_brand">Note.</p>
            <p>
              If you choose to undelegate, there is a 7-day waiting period the
              process completes.
            </p>
            <p>
              You can claim your BNB after a 7-day period following
              undelegation.
            </p>
          </div>
          <div className="d-flex align-items-center justify-content-between mb_20">
            <p className="footer_title">From Validator</p>
            <div className="d-flex align-items-center gap-2">
              <img src="/assets/img/user/user2.png" />
              <span className="user_data">Legendill</span>
              <span className="rank">APY : 0.56%</span>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between position-relative mb_20">
            <p className="footer_title">To Validator</p>
            <div className="d-flex align-items-center u_validator gap-2 ">
              <div className="user_profile">
                <img src="/assets/img/user/user1.png" />
              </div>
              <span className="user_data text_main_dark">Nval.</span>
              <span className="rank">APY : 0.56%</span>
              <div className="dropdown main_drop">
                <button
                  className="btn p-0"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span className="px-md-2 px-1 cursor_pointer">
                    <ArrowDown />
                  </span>
                </button>
                <ul className="dropdown-menu drop_menu">
                  <li className="dropdown-item d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center gap-2">
                      <div className="user_profile">
                        <img src="/assets/img/user/user1.png" />
                      </div>
                      <span className="user_data text_main_dark">
                        Legendill
                      </span>
                    </div>
                    <div>
                      <span className="rank">APY : 0.56%</span>
                    </div>
                  </li>
                  <li className="dropdown-item d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center gap-2">
                      <div className="user_profile">
                        <img src="/assets/img/user/user1.png" />
                      </div>
                      <span className="user_data text_main_dark">
                        Legendill
                      </span>
                    </div>
                    <div>
                      <span className="rank">APY : 0.56%</span>
                    </div>
                  </li>
                  <li className="dropdown-item d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center gap-2">
                      <div className="user_profile">
                        <img src="/assets/img/user/user1.png" />
                      </div>
                      <span className="user_data text_main_dark">
                        Legendill
                      </span>
                    </div>
                    <div>
                      <span className="rank">APY : 0.56%</span>
                    </div>
                  </li>
                  <li className="dropdown-item d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center gap-2">
                      <div className="user_profile">
                        <img src="/assets/img/user/user1.png" />
                      </div>
                      <span className="user_data text_main_dark">
                        Legendill
                      </span>
                    </div>
                    <div>
                      <span className="rank">APY : 0.56%</span>
                    </div>
                  </li>
                  <li className="dropdown-item d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center gap-2">
                      <div className="user_profile">
                        <img src="/assets/img/user/user1.png" />
                      </div>
                      <span className="user_data text_main_dark">
                        Legendill
                      </span>
                    </div>
                    <div>
                      <span className="rank">APY : 0.56%</span>
                    </div>
                  </li>
                  <li className="dropdown-item d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center gap-2">
                      <div className="user_profile">
                        <img src="/assets/img/user/user1.png" />
                      </div>
                      <span className="user_data text_main_dark">
                        Legendill
                      </span>
                    </div>
                    <div>
                      <span className="rank">APY : 0.56%</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="amount_card mb_20">
            <p>UnDelegate Amount</p>
            <div className="d-flex align-items-center justify-content-between">
              <h3 className="amount">1.0</h3>
              <span className="max_price">MAX</span>
            </div>
          </div>
          <div className="mb_20">
            <p className="footer_title">
              Available Balance:{" "}
              <span className="light_gray">3.0000000123242 LYC</span>
            </p>
          </div>
          <div className="mb_20">
            <div className="d-flex align-items-center justify-content-between mb-1">
              <p className="footer_title">Validator</p>
              <p className="footer_title light_gray">0.0228665 LYC (~$9.42)</p>
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <p className="footer_title">Total Amount</p>
              <p className="footer_title light_gray">
                1.0228665 LYC (~$142.51)
              </p>
            </div>
          </div>
          <button className="btn modal_btn">UnDelegate</button>
        </Modal.Body>
      </Modal>
    </>
  );
}
