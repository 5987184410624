import React, { useState } from "react";
import Header from "../component/Header";
import {
  APY,
  ArrowUp,
  CopyIcon,
  LeftArrow,
  RightArrow,
  Staked,
  Validators,
} from "../component/icons";
import { Link } from "react-router-dom";
import Delegate from "../component/modals/Delegate";
import Undelegate from "../component/modals/Undelegate";
import Redelegate from "../component/modals/Redelegate";

export default function MyStaking() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Header />
      <div className="layout">
        <div className="container">
          <div className="main">
            <h2 className="user_name mb-3">My Staking</h2>
            <p className="text_pera">
              By Staking BNB, you can contribute to LYC network security & earn
              Rewards.
            </p>
            <div className="validator_card mt-4">
              <div className="staking_data">
                <div className="d-flex flex-column justify-content-between gap-2 py-2">
                  <p className="card_title">Available</p>
                  <h5 className="card_price">5342.2343 LYC</h5>
                </div>
                <div className="vertical_border"></div>
                <div className="d-flex flex-column justify-content-between gap-2 py-2">
                  <p className="card_title">Delegated</p>
                  <h5 className="card_price">62.10 LYC</h5>
                </div>
                <div className="vertical_border"></div>
                <div className="d-flex flex-column justify-content-between gap-2 py-2">
                  <p className="card_title">Unbounding</p>
                  <h5 className="card_price">0.00 LYC</h5>
                </div>
                <div className="vertical_border"></div>
                <div className="d-flex flex-column justify-content-between gap-2 py-2">
                  <p className="card_title">In Order</p>
                  <h5 className="card_price">0.00 LYC</h5>
                </div>
                <div className="vertical_border"></div>
                <div className="d-flex flex-column justify-content-between gap-2 py-2">
                  <p className="card_title">Frozen</p>
                  <h5 className="card_price">0.00 LYC</h5>
                </div>
              </div>
            </div>
            <div className="mt-4">
              <h2 className="second_title">Delegation (6)</h2>
              <div className="table-responsive">
              <table className="table validator_table stking_data border-0 mb-0">
                <thead>
                  <tr>
                    <th className="ps-3">Status</th>
                    <th>Validator Name</th>
                    <th>Amount</th>
                    <th>Staking Since</th>
                    <th className="text-end pe-3">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div className="d-flex align-items-center gap-1">
                        <span className="dote"></span>
                        <span className="text_green text-uppercase">
                          Active
                        </span>
                      </div>
                    </td>
                    <td>Delegate</td>
                    <td>
                      <div className="d-flex gap-2 align-items-center">
                        <span className="user_data">30.00</span>
                        <span className="user_data">LYC</span>
                      </div>
                    </td>
                    <td>2024-07-22 10:52:27</td>
                    <td>
                      <div className="d-flex align-items-center  justify-content-end gap-2">
                        <button className="btn table_btn btn1" onClick={() => setShow(true)}>Undelegate</button>
                        <button className="btn table_btn btn2" onClick={() => setShow(true)}>Redelegate</button>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="d-flex align-items-center gap-1">
                        <span className="dote"></span>
                        <span className="text_green text-uppercase">
                          Active
                        </span>
                      </div>
                    </td>
                    <td>Delegate</td>
                    <td>
                      <div className="d-flex gap-2 align-items-center">
                        <span className="user_data">30.00</span>
                        <span className="user_data">LYC</span>
                      </div>
                    </td>
                    <td>2024-07-22 10:52:27</td>
                    <td>
                    <div className="d-flex align-items-center  justify-content-end gap-2">
                        <button className="btn table_btn btn1" onClick={() => setShow(true)}>Undelegate</button>
                        <button className="btn table_btn btn2" onClick={() => setShow(true)}>Redelegate</button>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="d-flex align-items-center gap-1">
                        <span className="dote"></span>
                        <span className="text_green text-uppercase">
                          Active
                        </span>
                      </div>
                    </td>
                    <td>Delegate</td>
                    <td>
                      <div className="d-flex gap-2 align-items-center">
                        <span className="user_data">30.00</span>
                        <span className="user_data">LYC</span>
                      </div>
                    </td>
                    <td>2024-07-22 10:52:27</td>
                    <td>
                    <div className="d-flex align-items-center  justify-content-end gap-2">
                        <button className="btn table_btn btn1" onClick={() => setShow(true)}>Undelegate</button>
                        <button className="btn table_btn btn2" onClick={() => setShow(true)}>Redelegate</button>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="d-flex align-items-center gap-1">
                        <span className="dote"></span>
                        <span className="text_green text-uppercase">
                          Active
                        </span>
                      </div>
                    </td>
                    <td>Delegate</td>
                    <td>
                      <div className="d-flex gap-2 align-items-center">
                        <span className="user_data">30.00</span>
                        <span className="user_data">LYC</span>
                      </div>
                    </td>
                    <td>2024-07-22 10:52:27</td>
                    <td>
                    <div className="d-flex align-items-center  justify-content-end gap-2">
                        <button className="btn table_btn btn1" onClick={() => setShow(true)}>Undelegate</button>
                        <button className="btn table_btn btn2" onClick={() => setShow(true)}>Redelegate</button>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="d-flex align-items-center gap-1">
                        <span className="dote"></span>
                        <span className="text_green text-uppercase">
                          Active
                        </span>
                      </div>
                    </td>
                    <td>Delegate</td>
                    <td>
                      <div className="d-flex gap-2 align-items-center">
                        <span className="user_data">30.00</span>
                        <span className="user_data">LYC</span>
                      </div>
                    </td>
                    <td>2024-07-22 10:52:27</td>
                    <td>
                    <div className="d-flex align-items-center  justify-content-end gap-2">
                        <button className="btn table_btn btn1" onClick={() => setShow(true)}>Undelegate</button>
                        <button className="btn table_btn btn2" onClick={() => setShow(true)}>Redelegate</button>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="d-flex align-items-center gap-1">
                        <span className="dote"></span>
                        <span className="text_green text-uppercase">
                          Active
                        </span>
                      </div>
                    </td>
                    <td>Delegate</td>
                    <td>
                      <div className="d-flex gap-2 align-items-center">
                        <span className="user_data">30.00</span>
                        <span className="user_data">LYC</span>
                      </div>
                    </td>
                    <td>2024-07-22 10:52:27</td>
                    <td>
                    <div className="d-flex align-items-center  justify-content-end gap-2">
                        <button className="btn table_btn btn1" onClick={() => setShow(true)}>Undelegate</button>
                        <button className="btn table_btn btn2" onClick={() => setShow(true)}>Redelegate</button>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="d-flex align-items-center gap-1">
                        <span className="dote"></span>
                        <span className="text_green text-uppercase">
                          Active
                        </span>
                      </div>
                    </td>
                    <td>Delegate</td>
                    <td>
                      <div className="d-flex gap-2 align-items-center">
                        <span className="user_data">30.00</span>
                        <span className="user_data">LYC</span>
                      </div>
                    </td>
                    <td>2024-07-22 10:52:27</td>
                    <td>
                    <div className="d-flex align-items-center  justify-content-end gap-2">
                        <button className="btn table_btn btn1" onClick={() => setShow(true)}>Undelegate</button>
                        <button className="btn table_btn btn2" onClick={() => setShow(true)}>Redelegate</button>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="d-flex align-items-center gap-1">
                        <span className="dote"></span>
                        <span className="text_green text-uppercase">
                          Active
                        </span>
                      </div>
                    </td>
                    <td>Delegate</td>
                    <td>
                      <div className="d-flex gap-2 align-items-center">
                        <span className="user_data">30.00</span>
                        <span className="user_data">LYC</span>
                      </div>
                    </td>
                    <td>2024-07-22 10:52:27</td>
                    <td>
                    <div className="d-flex align-items-center  justify-content-end gap-2">
                        <button className="btn table_btn btn1" onClick={() => setShow(true)}>Undelegate</button>
                        <button className="btn table_btn btn2" onClick={() => setShow(true)}>Redelegate</button>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="d-flex align-items-center gap-1">
                        <span className="dote"></span>
                        <span className="text_green text-uppercase">
                          Active
                        </span>
                      </div>
                    </td>
                    <td>Delegate</td>
                    <td>
                      <div className="d-flex gap-2 align-items-center">
                        <span className="user_data">30.00</span>
                        <span className="user_data">LYC</span>
                      </div>
                    </td>
                    <td>2024-07-22 10:52:27</td>
                    <td>
                    <div className="d-flex align-items-center  justify-content-end gap-2">
                        <button className="btn table_btn btn1" onClick={() => setShow(true)}>Undelegate</button>
                        <button className="btn table_btn btn2" onClick={() => setShow(true)}>Redelegate</button>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="d-flex align-items-center gap-1">
                        <span className="dote"></span>
                        <span className="text_green text-uppercase">
                          Active
                        </span>
                      </div>
                    </td>
                    <td>Delegate</td>
                    <td>
                      <div className="d-flex gap-2 align-items-center">
                        <span className="user_data">30.00</span>
                        <span className="user_data">LYC</span>
                      </div>
                    </td>
                    <td>2024-07-22 10:52:27</td>
                    <td>
                    <div className="d-flex align-items-center  justify-content-end gap-2">
                        <button className="btn table_btn btn1" onClick={() => setShow(true)}>Undelegate</button>
                        <button className="btn table_btn btn2" onClick={() => setShow(true)}>Redelegate</button>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="d-flex align-items-center gap-1">
                        <span className="dote"></span>
                        <span className="text_green text-uppercase">
                          Active
                        </span>
                      </div>
                    </td>
                    <td>Delegate</td>
                    <td>
                      <div className="d-flex gap-2 align-items-center">
                        <span className="user_data">30.00</span>
                        <span className="user_data">LYC</span>
                      </div>
                    </td>
                    <td>2024-07-22 10:52:27</td>
                    <td>
                    <div className="d-flex align-items-center  justify-content-end gap-2">
                        <button className="btn table_btn btn1" onClick={() => setShow(true)}>Undelegate</button>
                        <button className="btn table_btn btn2" onClick={() => setShow(true)}>Redelegate</button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              </div>
              <div className="table_footer">
                <div className="d-flex flex-md-row flex-column align-items-md-center gap-2">
                    <p className="footer_title">Row per Page</p>
                    <select className="form-select page_select">
                        <option>10</option>
                        <option>15</option>
                        <option>20</option>
                    </select>
                </div>
                <div className="d-flex flex-md-row flex-column align-items-md-center gap-2">
                    <p className="footer_title">Total of 46 Validators</p>
                    <ul className="table_pagination">
                        <li>
                            <Link>
                                <LeftArrow />
                            </Link>
                        </li>
                        <li>
                            <Link className="active">1</Link>
                        </li>
                        <li>
                            <Link>2</Link>
                        </li>
                        <li>
                            <Link>3</Link>
                        </li>
                        <li>
                            <Link>
                                <RightArrow />
                            </Link>
                        </li>
                    </ul>
                </div>
              </div>
              <Delegate show={show} handleClose={handleClose} />
            </div>

            <div className="mt-4">
              <ul
                className="nav nav-pills align-items-center gap-2 mb-3"
                id="pills-tab"
                role="tablist"
              >
                <h2 className="second_title mb-0">History</h2>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link history_btn active"
                    id="pills-home-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-home"
                    type="button"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected="true"
                  >
                    Staking Transactions
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link history_btn"
                    id="pills-profile-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-profile"
                    type="button"
                    role="tab"
                    aria-controls="pills-profile"
                    aria-selected="false"
                  >
                    Rewards
                  </button>
                </li>
              </ul>
              <div className="tab-content" id="pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="pills-home"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                  tabindex="{0}"
                >
                  <div className="table-responsive">
                  <table className="table validator_table border-0 mb-0">
                    <thead>
                      <tr>
                        <th className="ps-3">Type</th>
                        <th>Amount</th>
                        <th>Validator Name</th>
                        <th>Status</th>
                        <th>Time</th>
                        <th className="text-end pe-3">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Undelegate</td>
                        <td>
                          <div className="d-flex gap-2 align-items-center">
                            <span className="user_data">10.00</span>
                            <span className="user_data">LYC</span>
                          </div>
                        </td>
                        <td>Legendill</td>
                        <td>
                          <div className="d-flex align-items-center gap-1">
                            <span className="dote"></span>
                            <span className="text_green text-uppercase">
                              Active
                            </span>
                          </div>
                        </td>
                        <td>2024-07-22 10:52:27</td>
                        <td>
                          <button className="btn table_btn btn_action">
                            <RightArrow />
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td>Undelegate</td>
                        <td>
                          <div className="d-flex gap-2 align-items-center">
                            <span className="user_data">10.00</span>
                            <span className="user_data">LYC</span>
                          </div>
                        </td>
                        <td>Legendill</td>
                        <td>
                          <div className="d-flex align-items-center gap-1">
                            <span className="dote"></span>
                            <span className="text_green text-uppercase">
                              Active
                            </span>
                          </div>
                        </td>
                        <td>2024-07-22 10:52:27</td>
                        <td>
                          <button className="btn table_btn btn_action">
                            <RightArrow />
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td>Undelegate</td>
                        <td>
                          <div className="d-flex gap-2 align-items-center">
                            <span className="user_data">10.00</span>
                            <span className="user_data">LYC</span>
                          </div>
                        </td>
                        <td>Legendill</td>
                        <td>
                          <div className="d-flex align-items-center gap-1">
                            <span className="dote"></span>
                            <span className="text_green text-uppercase">
                              Active
                            </span>
                          </div>
                        </td>
                        <td>2024-07-22 10:52:27</td>
                        <td>
                          <button className="btn table_btn btn_action">
                            <RightArrow />
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td>Undelegate</td>
                        <td>
                          <div className="d-flex gap-2 align-items-center">
                            <span className="user_data">10.00</span>
                            <span className="user_data">LYC</span>
                          </div>
                        </td>
                        <td>Legendill</td>
                        <td>
                          <div className="d-flex align-items-center gap-1">
                            <span className="dote"></span>
                            <span className="text_green text-uppercase">
                              Active
                            </span>
                          </div>
                        </td>
                        <td>2024-07-22 10:52:27</td>
                        <td>
                          <button className="btn table_btn btn_action">
                            <RightArrow />
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td>Undelegate</td>
                        <td>
                          <div className="d-flex gap-2 align-items-center">
                            <span className="user_data">10.00</span>
                            <span className="user_data">LYC</span>
                          </div>
                        </td>
                        <td>Legendill</td>
                        <td>
                          <div className="d-flex align-items-center gap-1">
                            <span className="dote"></span>
                            <span className="text_green text-uppercase">
                              Active
                            </span>
                          </div>
                        </td>
                        <td>2024-07-22 10:52:27</td>
                        <td>
                          <button className="btn table_btn btn_action">
                            <RightArrow />
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  </div>
                  <div className="table_footer">
                <div className="d-flex flex-md-row flex-column align-items-md-center gap-2">
                    <p className="footer_title">Row per Page</p>
                    <select className="form-select page_select">
                        <option>10</option>
                        <option>15</option>
                        <option>20</option>
                    </select>
                </div>
                <div className="d-flex flex-md-row flex-column align-items-md-center gap-2">
                    <p className="footer_title">Total of 46 Validators</p>
                    <ul className="table_pagination">
                        <li>
                            <Link>
                                <LeftArrow />
                            </Link>
                        </li>
                        <li>
                            <Link className="active">1</Link>
                        </li>
                        <li>
                            <Link>2</Link>
                        </li>
                        <li>
                            <Link>3</Link>
                        </li>
                        <li>
                            <Link>
                                <RightArrow />
                            </Link>
                        </li>
                    </ul>
                </div>
              </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="pills-profile"
                  role="tabpanel"
                  aria-labelledby="pills-profile-tab"
                  tabindex="{0}"
                >
                  <div className="table-responsive">
                  <table className="table validator_table border-0 mb-0">
                    <thead>
                      <tr>
                        <th>Amount</th>
                        <th>Validator Name</th>
                        <th>Time</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div className="d-flex gap-2 align-items-center">
                            <span className="user_data">10.00</span>
                            <span className="user_data">LYC</span>
                          </div>
                        </td>
                        <td>Legendill</td>
                        <td className="text-start">2024-07-22 10:52:27</td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex gap-2 align-items-center">
                            <span className="user_data">10.00</span>
                            <span className="user_data">LYC</span>
                          </div>
                        </td>
                        <td>Legendill</td>
                        <td className="text-start">2024-07-22 10:52:27</td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex gap-2 align-items-center">
                            <span className="user_data">10.00</span>
                            <span className="user_data">LYC</span>
                          </div>
                        </td>
                        <td>Legendill</td>
                        <td className="text-start">2024-07-22 10:52:27</td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex gap-2 align-items-center">
                            <span className="user_data">10.00</span>
                            <span className="user_data">LYC</span>
                          </div>
                        </td>
                        <td>Legendill</td>
                        <td className="text-start">2024-07-22 10:52:27</td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex gap-2 align-items-center">
                            <span className="user_data">10.00</span>
                            <span className="user_data">LYC</span>
                          </div>
                        </td>
                        <td>Legendill</td>
                        <td className="text-start">2024-07-22 10:52:27</td>
                      </tr>
                    </tbody>
                  </table>
                  </div>
                  <div className="table_footer">
                <div className="d-flex flex-md-row flex-column align-items-md-center gap-2">
                    <p className="footer_title">Row per Page</p>
                    <select className="form-select page_select">
                        <option>10</option>
                        <option>15</option>
                        <option>20</option>
                    </select>
                </div>
                <div className="d-flex flex-md-row flex-column align-items-md-center gap-2">
                    <p className="footer_title">Total of 46 Validators</p>
                    <ul className="table_pagination">
                        <li>
                            <Link>
                                <LeftArrow />
                            </Link>
                        </li>
                        <li>
                            <Link className="active">1</Link>
                        </li>
                        <li>
                            <Link>2</Link>
                        </li>
                        <li>
                            <Link>3</Link>
                        </li>
                        <li>
                            <Link>
                                <RightArrow />
                            </Link>
                        </li>
                    </ul>
                </div>
              </div>
                </div>
              </div>
            </div>
          </div>
          <Undelegate show={show} handleClose={handleClose} />
          <Redelegate show={show} handleClose={handleClose} />
        </div>
      </div>
    </>
  );
}
