import { defaultWagmiConfig } from "@web3modal/wagmi";
import { lycanChain, lycanChainBeta } from "./chain";

import { lycan } from "viem/chains";
import { createConfig, http } from "wagmi";
import { createClient } from "viem";
import { RPC } from "../../constants";

// 1. Your WalletConnect Cloud project ID
const projectId = "05bf1a3bdce78ac897ccc68fafd1c7af";

const metadata = {
  name: "lycan-staking",
  description: "staking",
  url: "https://web3modal.com", // origin must match your domain & subdomain
  icons: ["https://avatars.githubusercontent.com/u/37784886"],
};

const chains = [lycanChain];

const myWallets = [
  "c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96",
  "622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0",
];

const config = defaultWagmiConfig({
  chains,
  projectId,
  metadata,
  myWallets,
  enableWalletConnect: true,
  autoConnect: true,
  //   ...wagmiOptions // Optional - Override createConfig parameters
});

export default config;
