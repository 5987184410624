import { Button, Modal, Spinner } from "react-bootstrap";
import { Close } from "../icons";
import { shortenAddress } from "../../utils";
import { convertDividePrice } from "../../utils/commonFunctions";
import { useEffect, useState } from "react";

import { useAccount } from "wagmi";
import { useContract, useEpochDetails } from "../../hooks/useStakingContract";

export default function Undelegate({
  show,
  handleClose,
  validator,
  balance,
  maxWithdrawAllowed,
  maxWithdrawOrderAllowed,
  orderedWithdrawAmount,
  orderWithdrawEpoch,
  stakeFirstEpoch,
  onOrderWithdraw,
  onWithdrawOrder,
  onDirectWithdraw,
  isLoading,
}) {
  const contract = useContract();

  const { currentEpoch, nextEpoch } = useEpochDetails();

  const { address } = useAccount();

  const [currentStep, setCurrentStep] = useState(1);
  const [directWithdraw, setDirectWithdraw] = useState(false);

  // const getCurrentEpoch = async () => {
  //   contract.read
  //     .stakingEpoch()
  //     .then((value) => {
  //       if (value) {
  //         console.log("current epoch : ", currentEpoch);
  //         setcurrentEpoch(value);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("useStaking pools error: ", error);
  //     });
  // };

  useEffect(() => {
    if (convertDividePrice(maxWithdrawOrderAllowed) > 0.0) {
      setCurrentStep(1);
      // console.log("current step is 1");
    }

    if (convertDividePrice(orderedWithdrawAmount) > 0.0) {
      setCurrentStep(2);
      // console.log("current step is 2");
    }

    if (convertDividePrice(maxWithdrawAllowed) > 0.0) {
      setCurrentStep(3);
      // console.log("current step is 2");
    }

    if (orderWithdrawEpoch) {
      if (currentEpoch > orderWithdrawEpoch) {
        // console.log("current epoch is greater");
        setCurrentStep(3);
      }
    }

    if (maxWithdrawAllowed) {
      setCurrentStep(4);
      setDirectWithdraw(true);
      // console.log("current step direct");
    }
  }, [
    maxWithdrawAllowed,
    maxWithdrawOrderAllowed,
    orderWithdrawEpoch,
    orderedWithdrawAmount,
  ]);

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        className="delegate"
        centered
        backdrop="static"
        keyboard="false"
      >
        <Modal.Header>
          <Modal.Title>Undelegate</Modal.Title>
          <Button className="btn-close" onClick={handleClose}>
            <Close />
          </Button>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="modal_card mb_20">
            <p className="text_brand">Note.</p>
            <p>
              If you choose to undelegate, there is a 7-day waiting period the
              process completes.
            </p>
            <p>
              You can claim your BNB after a 7-day period following
              undelegation.
            </p>
          </div>
          <div className="d-flex align-items-center justify-content-between mb_20">
            <p className="footer_title">Validator</p>
            <div className="d-flex align-items-center gap-2">
              <img src="/assets/img/user/user2.png" />
              <span className="user_data">{shortenAddress(validator)}</span>
              <span className="rank">APY : 0.56%</span>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between mb_20">
            <p className="footer_title">Current Step</p>
            <div className="d-flex align-items-center gap-2">
              <span className="user_data">{currentStep}</span>
            </div>
          </div>
          {maxWithdrawAllowed && (
            <div className="d-flex align-items-center justify-content-between mb_20">
              <p className="footer_title">Max Withdraw Allowed</p>
              <div className="d-flex align-items-center gap-2">
                <span className="user_data">
                  {convertDividePrice(maxWithdrawAllowed)}
                </span>
              </div>
            </div>
          )}
          {maxWithdrawOrderAllowed && (
            <div className="d-flex align-items-center justify-content-between mb_20">
              <p className="footer_title">Max Withdraw Order Allowed</p>
              <div className="d-flex align-items-center gap-2">
                <span className="user_data">
                  {convertDividePrice(maxWithdrawOrderAllowed)}
                </span>
              </div>
            </div>
          )}
          {orderedWithdrawAmount && (
            <div className="d-flex align-items-center justify-content-between mb_20">
              <p className="footer_title">Ordered Withdraw Amount</p>
              <div className="d-flex align-items-center gap-2">
                <span className="user_data">
                  {convertDividePrice(orderedWithdrawAmount)}
                </span>
              </div>
            </div>
          )}

          <div className="d-flex align-items-center justify-content-between mb_20">
            <p className="footer_title">Next Epoch In</p>
            <div className="d-flex align-items-center gap-2">
              <span className="user_data">{nextEpoch}</span>
            </div>
          </div>

          <div className="amount_card mb_20">
            <p>UnDelegate Amount</p>
            <div className="d-flex align-items-center justify-content-between">
              <h3 className="amount">
                {maxWithdrawAllowed
                  ? convertDividePrice(maxWithdrawAllowed)
                  : maxWithdrawOrderAllowed
                  ? convertDividePrice(maxWithdrawOrderAllowed)
                  : orderedWithdrawAmount
                  ? convertDividePrice(orderedWithdrawAmount)
                  : null}
              </h3>
            </div>
          </div>
          <div className="mb_20">
            <p className="footer_title">
              Available Balance:{" "}
              <span className="light_gray">{balance} LYC</span>
            </p>
          </div>
          <div className="mb_20">
            <div className="d-flex align-items-center justify-content-between mb-1">
              <p className="footer_title">Validator</p>
              <p className="footer_title light_gray">0.0228665 LYC (~$9.42)</p>
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <p className="footer_title">Total Amount</p>
              <p className="footer_title light_gray">
                1.0228665 LYC (~$142.51)
              </p>
            </div>
          </div>

          {isLoading && (
            <div className="d-flex mb-4 align-items-center justify-content-center text-center not-found-container">
              <Spinner animation="border text-white " role="status">
                <span className=""></span>
              </Spinner>
            </div>
          )}

          {directWithdraw ? (
            <button onClick={onDirectWithdraw} className="btn modal_btn">
              Withdraw
            </button>
          ) : currentStep == 1 ? (
            <button onClick={onOrderWithdraw} className="btn modal_btn">
              Order Withdraw
            </button>
          ) : currentStep == 2 ? (
            <button className="btn modal_btn">
              Waiting for epoch to complete
            </button>
          ) : currentStep == 3 ? (
            <button onClick={onWithdrawOrder} className="btn modal_btn">
              Withdraw Order
            </button>
          ) : currentStep == 4 ? (
            <button onClick={onDirectWithdraw} className="btn modal_btn">
              Withdraw
            </button>
          ) : null}
        </Modal.Body>
      </Modal>
    </>
  );
}
