import { Button, Form, InputGroup, Modal, Spinner } from "react-bootstrap";
import { Close } from "../icons";
import { shortenAddress } from "../../utils";
import {
  useContract,
  usePoolDetails,
  useWeb3,
} from "../../hooks/useStakingContract";
import { useEffect, useState } from "react";
import { convertDividePrice } from "../../utils/commonFunctions";
import Web3 from "web3";
import Swal from "sweetalert2";

import { CONFIRMATIONS, CONTRACT_ADDRESS } from "../../constants";

import { useAccount, usePublicClient } from "wagmi";

export default function Delegate({
  selectedPool,
  show,
  handleClose,
  balance,
  minStake,
  currentEpoch,
  stakingAllowed,
}) {
  const { address } = useAccount();

  const contract = useContract();
  const publicClient = usePublicClient();

  const [amount, setamount] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const close = () => {
    setamount("");
    handleClose();
  };

  const handleAmtChange = (e) => {
    setamount(e.target.value);
    // console.log(amount);
  };

  const delegate = async () => {
    if (amount) {
      setIsLoading(true);

      const weiAmt = Web3.utils.toWei(amount, "ether");
      // console.log("input wei amount", weiAmt);
      // console.log("input pool address", selectedPool);

      if (parseFloat(amount) >= parseFloat(convertDividePrice(minStake))) {
        const hash = await contract.write
          .stake([selectedPool, weiAmt], {
            value: weiAmt,
          })
          .catch((error) => {
            setIsLoading(false);
            alert(error);
          });

        await publicClient
          .waitForTransactionReceipt({
            hash,
            confirmations: CONFIRMATIONS,
          })
          .then((value) => {
            if (value.status === "success") {
              setIsLoading(false);
              Swal.fire({
                icon: "success",
                title: "Stake Successful",
                text: "",
                footer: `<a href="https://${process.env.REACT_APP_EXPLORER_SUBDOMAIN}.lycanchain.com/tx/${value.hash}" target='_blank'>View Transaction</a>`,
              });

              handleClose();
            } else {
              alert("error Transaction revert");
            }
          });
      } else {
        setIsLoading(false);
        alert("minimum value is: ", convertDividePrice(minStake));
      }
    } else {
      alert("please enter amount");
    }
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        className="delegate"
        centered
        backdrop="static"
        keyboard="false"
      >
        <Modal.Header>
          <Modal.Title>Delegate</Modal.Title>
          <Button className="btn-close" onClick={close}>
            <Close />
          </Button>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="modal_card mb_20">
            <p className="text_brand">Note.</p>
            <p>
              After you send the delegate transaction, you will receive your
              first staking rewards at T+1 UTC 00:00.
            </p>
            <p>
              If you choose to undelegated, there is a 7-day waiting period
              before the process completes.
            </p>
          </div>
          <div className="d-flex align-items-center justify-content-between mb_20">
            <p className="footer_title">Validator</p>
            <div className="d-flex align-items-center gap-2">
              <img src="/assets/img/user/user2.png" />
              <span className="user_data">{shortenAddress(selectedPool)}</span>
              <span className="position bg_dark_200">Cabinet</span>
            </div>
          </div>
          <div className="amount_card mb_20">
            <p>
              Delegate Amount (min. {parseInt(convertDividePrice(minStake))})
            </p>
            <InputGroup size="lg">
              <Form.Control
                type="number"
                onChange={handleAmtChange}
                aria-label="Large"
                aria-describedby="inputGroup-sizing-sm"
                className="bg-transperant border-0"
                value={amount}
              />
            </InputGroup>
            {/* <h3 className="amount">0.0</h3> */}
          </div>
          <div className="mb_20">
            <p className="footer_title">
              Available Balance:{" "}
              <span className="light_gray">{balance} LYC</span>
            </p>
          </div>

          {isLoading && (
            <div className="d-flex mb-4 align-items-center justify-content-center text-center not-found-container">
              <Spinner animation="border text-white " role="status">
                <span className=""></span>
              </Spinner>
            </div>
          )}

          {isLoading ? (
            <button className="btn modal_btn">Loading...</button>
          ) : parseFloat(balance) < parseFloat(convertDividePrice(minStake)) ? (
            <button className="btn modal_btn">Insufficient Balance</button>
          ) : address ? (
            <button onClick={delegate} className="btn modal_btn">
              Delegate
            </button>
          ) : (
            <button className="btn modal_btn">Connect Wallet</button>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}
