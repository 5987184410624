import { useState, useEffect, useMemo } from "react";

import { useAccount, useReadContract, useWatchContractEvent } from "wagmi";

import ABI from "../abi/staking_aura.json";
import VALIDATOR_ABI from "../abi/validatorset_aura.json";
import {
  CONTRACT_ADDRESS,
  RPC,
  VALIDATOR_CONTRACT_ADDRESS_BETA,
} from "../constants";

import { useWalletClient } from "wagmi";
import { createPublicClient, getContract, http } from "viem";

import Web3 from "web3";

import moment from "moment";

import { convertDividePrice } from "../utils/commonFunctions";

import { formatTime } from "../utils";
import { lycanChainBeta, lycanChain } from "../component/_providers/chain";
import { getClient } from "wagmi/actions";
import config from "../component/_providers/config";

const web3 = new Web3(process.env.REACT_APP_RPC);

function usePublicClient() {
  return createPublicClient({
    chain: lycanChain,
    transport: http(),
    // transport: http(RPC),
  });
}

function useContract() {
  const { data: signer } = useWalletClient();
  const publicClient = usePublicClient();

  return useMemo(() => {
    if (!process.env.REACT_APP_CONTRACT_ADDRESS || !ABI) return null;
    try {
      return getContract({
        address: process.env.REACT_APP_CONTRACT_ADDRESS,
        // address: CONTRACT_ADDRESS,
        abi: ABI,
        client: { public: publicClient, wallet: signer },
      });
    } catch (error) {
      console.error("Failed to get contract", error);
      return null;
    }
  }, [signer]);
}

function useValidatorContract() {
  const { data: signer } = useWalletClient();
  const publicClient = usePublicClient();

  return useMemo(() => {
    if (!process.env.REACT_APP_VALIDATOR_CONTRACT_ADDRESS || !VALIDATOR_ABI)
      return null;
    try {
      return getContract({
        address: process.env.REACT_APP_VALIDATOR_CONTRACT_ADDRESS,
        // address: VALIDATOR_CONTRACT_ADDRESS_BETA,
        abi: VALIDATOR_ABI,
        client: { public: publicClient, wallet: signer },
      });
    } catch (error) {
      console.error("Failed to get contract", error);
      return null;
    }
  }, [signer]);
}

const useStakingAllowed = () => {
  const contract = useContract();
  const [stakingAllowed, setStakingAllowed] = useState(false);

  useEffect(() => {
    contract.read
      .areStakeAndWithdrawAllowed()

      .then((value) => {
        setStakingAllowed(value);
        // console.log("staking allowed :", value);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return stakingAllowed;
};

const useEvents = (fromPoolStakingAddress, staker) => {
  const [events, setevents] = useState(null);
  const web3 = new Web3("https://rpc-beta.lycanchain.com");
  let contract = new web3.eth.Contract(ABI, CONTRACT_ADDRESS);

  useEffect(() => {
    // contract
    //   .getPastEvents("claimReward", {
    //     filter: { fromPoolStakingAddress, staker },
    //     fromBlock: 0,
    //     toBlock: "latest",
    //   })
    //   .then(async (events) => {
    //     console.log("claim reward events : ", events);
    //     // setevents(events);
    //   });
  }, [events]);

  return events;
};

const useEpochDetails = () => {
  const contract = useContract();
  const [currentEpoch, setcurrentEpoch] = useState("");
  const [nextEpoch, setnextEpoch] = useState("");

  useEffect(() => {
    contract.read
      .stakingEpoch()
      .then((value) => {
        if (value) {
          setcurrentEpoch(value);
        }
      })
      .catch((error) => {
        console.error("useStaking pools error: ", error);
      });
  }, [contract]);

  const getNextEpoch = async () => {
    const nextEpochIn = await contract.read.stakingEpochEndBlock();

    const block = await web3.eth.getBlockNumber();
    const seconds =
      (parseInt(nextEpochIn.toString()) - parseInt(block.toString())) * 3;
    const relativeTime = moment.duration(seconds, "seconds").humanize();
    const days = moment
      .utc(seconds * 1000)
      .format("DD[D] : HH[H] : mm[M] : ss[S]");

    const time = formatTime(seconds);

    console.log("formate time ", time);
    setnextEpoch(`${time}`);
  };

  useEffect(() => {
    getNextEpoch();
    const intervalId = setInterval(getNextEpoch, 3000);
    return () => clearInterval(intervalId);
  }, []);

  return { currentEpoch, nextEpoch };
};

const useGetPools = () => {
  const contract = useContract();
  const [pools, setPools] = useState([]);
  const [poolIds, setPoolIds] = useState([]);

  const [poolsInactive, setPoolsInactive] = useState([]);
  const [poolsInactiveIds, setPoolsInactiveIds] = useState([]);

  //  Get Pools
  contract.read
    .getPools()
    .then((value) => {
      if (value) {
        setPoolIds(value.map((pool) => pool.toString()));
        setPools(value.map((pool) => Web3.utils.toHex(pool)));
      }
    })
    .catch((error) => {
      console.error("useStaking pools error: ", error);
    });

  //  Get Pools Inactive
  contract.read
    .getPoolsInactive()
    .then((value) => {
      if (value) {
        setPoolsInactive(value.map((pool) => pool.toString()));
        setPoolsInactiveIds(value.map((pool) => Web3.utils.toHex(pool)));
      }
    })
    .catch((error) => {
      console.error(error);
    });

  return { pools, poolIds, poolsInactive, poolsInactiveIds };
};

export const useGetActivePools = () => {
  const [pools, setPools] = useState([]);
  const [poolIds, setPoolIds] = useState([]);

  const [poolsInactive, setPoolsInactive] = useState([]);
  const [poolsInactiveIds, setPoolsInactiveIds] = useState([]);

  const contract = useContract();
  contract.read
    .getPools()
    .then((value) => {
      if (value) {
        setPoolIds(value.map((pool) => pool.toString()));
        setPools(value.map((pool) => Web3.utils.toHex(pool)));
      }
    })
    .catch((error) => {
      console.error("useStaking pools error: ", error);
    });

  return pools;
};

// const useStakingContract = () => {
//   const contract = useContract();
//   const [stakingAllowed, setStakingAllowed] = useState(false);

//   const [currentEpoch, setcurrentEpoch] = useState(null);

//   const [validatorMinStake, setValidatorMinStake] = useState("");
//   const [delegatorMinStake, setDelegatorMinStake] = useState("");

//   const [pools, setPools] = useState([]);
//   const [poolIds, setPoolIds] = useState([]);

//   const [poolsInactive, setPoolsInactive] = useState([]);
//   const [poolsInactiveIds, setPoolsInactiveIds] = useState([]);

//   const [poolsToBeElected, setPoolsToBeElected] = useState([]);
//   const [poolsToBeElectedIds, setPoolsToBeElectedIds] = useState([]);

//   const [poolsToBeRemoved, setPoolsToBeRemoved] = useState([]);
//   const [poolsToBeRemovedIds, setPoolsToBeRemovedIds] = useState([]);

//   useMemo(() => {
//     //Current Epoch
//     contract.read
//       .stakingEpoch()

//       .then((value) => {
//         setcurrentEpoch(value);
//       })
//       .catch((error) => {
//         console.error(error);
//       });

//     //Staking allowed?
//     contract.read
//       .areStakeAndWithdrawAllowed()

//       .then((value) => {
//         setStakingAllowed(value);
//       })
//       .catch((error) => {
//         console.error(error);
//       });

//     // Validator Min Stake
//     contract.read
//       .candidateMinStake()

//       .then((value) => {
//         setValidatorMinStake(value);
//       })
//       .catch((error) => {
//         console.error(error);
//       });

//     // Delegator Min Stake
//     contract.read
//       .delegatorMinStake()

//       .then((value) => {
//         setDelegatorMinStake(value);
//       })
//       .catch((error) => {
//         console.error(error);
//       });

// //  Get Pools
// contract.read
//   .getPools()

//   .then((value) => {
//     if (value) {
//       setPoolIds(value.map((pool) => pool.toString()));
//       setPools(value.map((pool) => Web3.utils.toHex(pool)));
//     }
//   })
//   .catch((error) => {
//     console.error("useStaking pools error: ", error);
//   });

//     //  Get Pools Inactive
//     contract.read
//       .getPoolsInactive()

//       .then((value) => {
//         if (value) {
//           setPoolsInactive(value.map((pool) => pool.toString()));
//           setPoolsInactiveIds(value.map((pool) => Web3.utils.toHex(pool)));
//         }
//       })
//       .catch((error) => {
//         console.error(error);
//       });

//     // Get Pools To Be Elected
//     contract.read
//       .getPoolsToBeElected()

//       .then((value) => {
//         if (value) {
//           setPoolsToBeElected(value.map((pool) => pool.toString()));
//           setPoolsToBeElectedIds(value.map((pool) => Web3.utils.toHex(pool)));
//         }
//       })
//       .catch((error) => {
//         console.error(error);
//       });

//     // Get Pools To Be Removed
//     contract.read
//       .getPoolsToBeRemoved()

//       .then((value) => {
//         if (value) {
//           setPoolsToBeRemoved(value.map((pool) => pool.toString()));
//           setPoolsToBeRemovedIds(value.map((pool) => Web3.utils.toHex(pool)));
//         }
//       })
//       .catch((error) => {
//         console.error(error);
//       });
//   }, [contract]);

//   return {
//     currentEpoch,
//     stakingAllowed,
//     pools,
//     poolIds,
//     validatorMinStake,
//     delegatorMinStake,
//     poolsInactive,
//     poolsInactiveIds,
//     poolsToBeElected,
//     poolsToBeElectedIds,
//     poolsToBeRemoved,
//     poolsToBeRemovedIds,
//   };
// };

const usePoolDetails = (pool) => {
  const contract = useContract();

  const { address } = useAccount();

  const [isLoading, setisLoading] = useState(false);

  const [maxWithdrawAllowed, setMaxWithdrawAllowed] = useState(null);
  const [maxWithdrawOrderAllowed, setMaxWithdrawOrderAllowed] = useState(null);

  const [isWithdrawAllowed, setisWithdrawAllowed] = useState(false);

  const [poolStakeAmount, setPoolStakeAmount] = useState(null);
  const [myStakeAmount, setMyStakeAmount] = useState(null);

  const [poolDelegators, setPoolDelegators] = useState();

  const [stakeFirstEpoch, setStakeFirstEpoch] = useState(null);

  // MAX WITHDRAW ALLOWED--------------------------------
  contract.read
    .maxWithdrawAllowed([pool, address])
    .then((value) => {
      setisLoading(false);
      setMaxWithdrawAllowed(value);

      if (Number(maxWithdrawAllowed) == 0) {
        setisWithdrawAllowed(false);
      }
    })
    .catch((error) => {
      setisLoading(false);
      console.error(error);
    });

  // MAX WITHDRAW ORDER ALLOWED--------------------------------
  contract.read
    .maxWithdrawOrderAllowed([pool, address])

    .then((value) => {
      setisLoading(false);
      setMaxWithdrawOrderAllowed(value);
      setisWithdrawAllowed(false);
    })
    .catch((error) => {
      setisLoading(false);
      console.error(error);
    });

  //Total Pool Stake Amount--------------------------------
  contract.read
    .stakeAmountTotal([pool])

    .then((value) => {
      setisLoading(false);
      setPoolStakeAmount(value);
    })
    .catch((error) => {
      setisLoading(false);
      console.error(error);
    });

  // My Stake Amount--------------------------------
  contract.read
    .stakeAmount([pool, address])

    .then((value) => {
      setisLoading(false);
      setMyStakeAmount(value);
    })
    .catch((error) => {
      setisLoading(false);
      console.error(error);
    });

  // Pool Delegators--------------------------------
  contract.read
    .poolDelegators([pool])

    .then((value) => {
      setisLoading(false);
      setPoolDelegators(value);

      // setPoolDelegators(poolDelegators.map(delegators=> delegators))
    })
    .catch((error) => {
      setisLoading(false);
      console.error(error);
    });

  // Staker First Epoch--------------------------------
  contract.read
    .stakeFirstEpoch([pool, address])

    .then((value) => {
      setisLoading(false);
      setStakeFirstEpoch(value);

      // setPoolDelegators(poolDelegators.map(delegators=> delegators))
    })
    .catch((error) => {
      setisLoading(false);
      console.error(error);
    });

  return {
    isLoading,
    maxWithdrawAllowed,
    isWithdrawAllowed,
    maxWithdrawOrderAllowed,
    poolStakeAmount,
    myStakeAmount,
    stakeFirstEpoch,
    poolDelegators,
  };
};

export {
  // useWeb3,

  useGetPools,
  usePoolDetails,
  useContract,
  useValidatorContract,
  useEpochDetails,
  useEvents,
  useStakingAllowed,
};
