import { Button, Form, InputGroup, Modal, Spinner } from "react-bootstrap";
import { Close } from "../icons";
import {
  hexToDecimal,
  isStringInArray,
  isValidURL,
  shortenAddress,
} from "../../utils";
import {
  useContract,
  usePoolDetails,
  useValidatorContract,
} from "../../hooks/useStakingContract";
import { useEffect, useState } from "react";
import { convertDividePrice } from "../../utils/commonFunctions";
import Web3 from "web3";
import Swal from "sweetalert2";

import ABI from "../../abi/staking_aura.json";
import { CHAIN_ID, CONFIRMATIONS, CONTRACT_ADDRESS } from "../../constants";

import { useAccount, usePublicClient } from "wagmi";

import { useBalance } from "wagmi";
import axios from "axios";

export default function Validate({
  selectedPool,
  show,
  handleClose,
  balance,
  minStake,
  pools,
}) {
  const { address } = useAccount();

  let { data, isError } = useBalance({
    address: address,
  });

  const contract = useContract();
  const validatorContract = useValidatorContract();

  const publicClient = usePublicClient();

  const [currentBalance, setcurrentBalance] = useState(0.0);

  const [validatorsList, setvalidatorsList] = useState([]);

  const [amount, setamount] = useState("");
  const [miningAddress, setMiningAddress] = useState("");
  const [poolName, setPoolName] = useState("");
  const [poolDesc, setPoolDesc] = useState("");
  const [rpcUrl, setRpcUrl] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const [isUrlValid, setisUrlValid] = useState(false);

  const [isValidRpc, setIsValidRpc] = useState(false);

  const [isInSync, setIsInSync] = useState(null);

  const [errorMessage, seterrorMessage] = useState(null);

  const close = () => {
    setamount("");
    setMiningAddress("");
    setPoolName("");
    setPoolDesc("");
    setRpcUrl("");
    setIsLoading(false);
    seterrorMessage(null);
    handleClose();
  };

  const handleAmtChange = (e) => {
    setamount(e.target.value);
  };
  const handleMiningAddChange = (e) => {
    setMiningAddress(e.target.value);
  };
  const handleNameChange = (e) => {
    setPoolName(e.target.value);
  };
  const handleDescChange = (e) => {
    setPoolDesc(e.target.value);
  };
  const handleRpcUrlChange = (e) => {
    setRpcUrl(e.target.value);
    setisUrlValid(isValidURL(e.target.value));

    if (e.target.value == "") {
      seterrorMessage(null);
    } else {
      if (isValidURL(e.target.value)) {
        seterrorMessage(null);
        validateRPC(e.target.value);
      } else {
        setisUrlValid(false);
        seterrorMessage("Url is not valid");
        setMiningAddress("");
      }
    }
  };

  const checkMiningAddress = (address) => {
    validatorContract.read
      .idByMiningAddress([address])
      .then((value) => {
        if (value) {
          if (value == "0") {
            seterrorMessage(null);
          } else {
            seterrorMessage("Address already exists");
          }
        }
      })
      .catch((error) => {
        console.error(" error: ", error);
      });
  };

  const validateRPC = async (url) => {
    setisUrlValid(true);
    await axios
      .post(
        url,
        {
          jsonrpc: "2.0",
          id: 0,
          method: "eth_chainId",
          params: [],
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        // console.log(response.data);
        const result = response.data.result;
        if (hexToDecimal(result) == process.env.REACT_APP_CHAIN_ID) {
          seterrorMessage(null);
          setIsValidRpc(true);
          checkSync(url);
        } else {
          setIsValidRpc(false);
          seterrorMessage("invalid RPC");
          setMiningAddress(null);
        }
      })
      .catch((error) => {
        console.error(error);
        setMiningAddress("");
      });
  };

  const checkSync = async (url) => {
    axios
      .post(
        url,
        {
          jsonrpc: "2.0",
          id: 0,
          method: "eth_syncing",
          params: [],
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        setIsInSync(response.data.result);
        if (response.data.result) {
          seterrorMessage("RPC in sync");
        } else {
          seterrorMessage(null);
          getMiningAddress(url);
        }
        // console.log("check sync: ", response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getMiningAddress = async (url) => {
    axios
      .post(
        url,
        {
          jsonrpc: "2.0",
          id: 0,
          method: "net_localAddress",
          params: [],
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        console.log("mining address ", response.data);
        if (response.data.result) {
          setMiningAddress(response.data.result);
          checkMiningAddress(response.data.result);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const validate = async () => {
    if (amount && poolName && poolDesc && miningAddress) {
      setIsLoading(true);

      const weiAmt = Web3.utils.toWei(amount, "ether");
      // console.log("input wei amount", weiAmt);
      // console.log("input pool address", selectedPool);

      if (parseFloat(amount) >= parseFloat(convertDividePrice(minStake))) {
        const hash = await contract.write
          .addPool([weiAmt, miningAddress, poolName, poolDesc], {
            value: weiAmt,
          })
          .catch((e) => {
            alert(e.message);
            setIsLoading(false);
          });

        const tx = await publicClient
          .waitForTransactionReceipt({
            hash,
            confirmations: CONFIRMATIONS,
          })
          .catch((e) => {
            console.log(e.message);
          });

        if (tx) {
          if (tx.status === "success") {
            Swal.fire({
              icon: "success",
              title: "Transaction Successful",
              text: "",
              footer: `<a href="https://${process.env.REACT_APP_EXPLORER_SUBDOMAIN}.lycanchain.com/tx/${hash}" target='_blank'>View Transaction</a>`,
            });

            handleClose();
          } else {
            alert("error Transaction revert");
          }
        }
      } else {
        setIsLoading(false);
        alert(
          "minimum value is: ",
          parseInt(convertDividePrice(minStake)).toString()
        );
      }
    } else {
      setIsLoading(false);
      alert("please enter details");
    }
  };

  useEffect(() => {
    if (data) {
      if (convertDividePrice(data.value)) {
        setcurrentBalance(convertDividePrice(data.value));
      } else {
        setcurrentBalance(0.0);
      }
    }
  }, [data]);

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        className="delegate"
        centered
        backdrop="static"
        keyboard="false"
      >
        <Modal.Header>
          <Modal.Title>Become A Validator</Modal.Title>
          <Button className="btn-close" onClick={close}>
            <Close />
          </Button>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="modal_card mb_20">
            <p className="text_brand">Note.</p>
            <p>
              After you send the delegate transaction, you will receive your
              first staking rewards at T+1 UTC 00:00.
            </p>
            <p>
              If you choose to undelegated, there is a 7-day waiting period
              before the process completes.
            </p>
          </div>
          {/* <div className="d-flex align-items-center justify-content-between mb_20">
            <p className="footer_title">Validator</p>
            <div className="d-flex align-items-center gap-2">
              <img src="/assets/img/user/user2.png" />
              <span className="user_data">{shortenAddress(selectedPool)}</span>
              <span className="position bg_dark_200">Cabinet</span>
            </div>
          </div> */}
          <div className="amount_card mb_20">
            <p>Pool Name</p>
            <InputGroup size="lg">
              <Form.Control
                type="text"
                onChange={handleNameChange}
                aria-label="Large"
                aria-describedby="inputGroup-sizing-sm"
                className="bg-transperant border-0"
                value={poolName}
              />
            </InputGroup>
            {/* <h3 className="amount">0.0</h3> */}
          </div>
          <div className="amount_card mb_20">
            <p>Description</p>
            <InputGroup size="lg">
              <Form.Control
                type="text"
                onChange={handleDescChange}
                aria-label="Large"
                aria-describedby="inputGroup-sizing-sm"
                className="bg-transperant border-0"
                value={poolDesc}
              />
            </InputGroup>
            {/* <h3 className="amount">0.0</h3> */}
          </div>
          <div className="amount_card mb_20">
            <p>RPC URL</p>
            <InputGroup size="lg">
              <Form.Control
                type="text"
                onChange={handleRpcUrlChange}
                aria-label="Large"
                aria-describedby="inputGroup-sizing-sm"
                className="bg-transperant border-0"
                value={rpcUrl}
              />
            </InputGroup>

            {errorMessage && (
              <p className="text_rpc_validate mt-2 text-white">
                {errorMessage}
              </p>
            )}

            {/* <h3 className="amount">0.0</h3> */}
          </div>
          <div className="amount_card mb_20">
            <p>Mining Address</p>
            <InputGroup size="lg">
              <Form.Control
                disabled
                type="text"
                onChange={handleMiningAddChange}
                aria-label="Large"
                aria-describedby="inputGroup-sizing-sm"
                className="textarea_disabled border-0"
                value={miningAddress}
              />
            </InputGroup>
            {/* <h3 className="amount">0.0</h3> */}
          </div>
          <div className="amount_card mb_20">
            <p>Stake Amount (min. {parseInt(convertDividePrice(minStake))})</p>
            <InputGroup size="lg">
              <Form.Control
                type="number"
                onChange={handleAmtChange}
                aria-label="Large"
                aria-describedby="inputGroup-sizing-sm"
                className="bg-transperant border-0"
                value={amount}
              />
            </InputGroup>
            {/* <h3 className="amount">0.0</h3> */}
          </div>

          <div className="mb_20">
            <p className="footer_title">
              Available Balance:{" "}
              <span className="light_gray">{currentBalance} LYC</span>
            </p>
          </div>
          {isLoading && (
            <div className="d-flex mb-4 align-items-center justify-content-center text-center not-found-container">
              <Spinner animation="border text-white " role="status">
                <span className=""></span>
              </Spinner>
            </div>
          )}

          {errorMessage ? (
            <button className="btn modal_btn_disabled">{errorMessage}</button>
          ) : isUrlValid && address && isValidRpc && !isInSync ? (
            <button onClick={validate} className="btn modal_btn">
              Become A Validator
            </button>
          ) : isLoading ? (
            <button className="btn modal_btn">Loading...</button>
          ) : null}
        </Modal.Body>
      </Modal>
    </>
  );
}
