import React, { useEffect, useState } from "react";
import Header from "../component/Header";
import {
  APY,
  ArrowUp,
  CopyIcon,
  LeftArrow,
  RightArrow,
  Staked,
  Validators,
} from "../component/icons";
import { Link } from "react-router-dom";
import Delegate from "../component/modals/Delegate";

import { useParams } from "react-router-dom";
import { shortenAddress } from "../utils";
import {
  useContract,
  useEpochDetails,
  useEvents,
  useStakingAllowed,
  useValidatorContract,
  useWeb3,
} from "../hooks/useStakingContract";
import { convertDividePrice, getEpochsBetween } from "../utils/commonFunctions";
import { useAccount, useBalance, usePublicClient } from "wagmi";
import { CONFIRMATIONS } from "../constants";

import Swal from "sweetalert2";

import Undelegate from "../component/modals/Undelegate";

export default function ValidatorsDetails() {
  const contract = useContract();

  const validatorContract = useValidatorContract();
  const publicClient = usePublicClient();

  let { currentEpoch } = useEpochDetails();

  let { id } = useParams();

  let { address } = useAccount();

  const { data, isError } = useBalance({
    address: address,
  });

  let { stakingAllowed } = useStakingAllowed();

  const [show, setShow] = useState(false);

  const [currentEpochList, setcurrentEpochList] = useState([]);

  const [isLoading, setisLoading] = useState(false);

  const [currentBalance, setcurrentBalance] = useState(null);

  const [showUndelegate, setShowUndelegate] = useState(false);

  const [poolName, setPoolName] = useState(null);
  const [poolDesc, setPoolDesc] = useState(null);

  const [maxWithdrawOrderAllowed, setmaxWithdrawOrderAllowed] = useState(null);
  const [orderedWithdrawAmount, setOrderedWithdrawAmount] = useState(null);
  const [maxWithdrawAllowed, setMaxWithdrawAllowed] = useState(null);

  const [delegatorMinStake, setDelegatorMinStake] = useState("");

  const [orderWithdrawEpoch, setOrderWithdrawEpoch] = useState(null);

  const [totalStake, settotalStake] = useState("");
  const [delegators, setDelegators] = useState([]);
  const [myStakeAmt, setMyStakeAmt] = useState(null);
  const [stakeFirstEpoch, setStakeFirstEpoch] = useState(null);

  const [rewardAmount, setrewardAmount] = useState(null);
  const [rewardWasTaken, setRewardWasTaken] = useState(false);

  const [miningAddress, setminingAddress] = useState(null);

  const events = useEvents(id, address);

  const [itemsPerPage, setitemsPerPage] = useState(10);
  const [currentItems, setcurrentItems] = useState([]);
  const [totalPages, settotalPages] = useState(1);

  const [currentPage, setcurrentPage] = useState(1);

  const calculateCurrentItems = () => {
    // console.log("calculate called");
    // Calculate the current items to display
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    setcurrentItems(delegators.slice(indexOfFirstItem, indexOfLastItem));

    settotalPages(Math.ceil(delegators.length / itemsPerPage));
  };

  const handleNext = () => {
    if (currentPage === totalPages) {
      return;
    } else {
      setcurrentPage(currentPage + 1);
    }
  };

  const handlePrev = () => {
    if (currentPage === 1) {
      setcurrentPage(1);
    } else {
      setcurrentPage(currentPage - 1);
    }
  };

  useEffect(() => {
    calculateCurrentItems();
  }, [delegators, itemsPerPage, currentPage]);

  const paginate = (pageNumber) => setcurrentPage(pageNumber);

  const handleClose = () => {
    setShow(false);
  };
  const handleUndelegateClose = () => setShowUndelegate(false);
  const handleShow = () => setShow(true);

  const getDelegatorMinStake = async () => {
    contract.read
      .delegatorMinStake()
      .then((value) => {
        if (value) {
          setDelegatorMinStake(value);
        }
      })
      .catch((error) => {
        console.error("useStaking pools error: ", error);
      });
  };

  const getMiningAddress = async () => {
    validatorContract.read
      .miningAddressById([id])
      .then((value) => {
        if (value) {
          setminingAddress(value);
        }
      })
      .catch((error) => {
        console.error("mining address error: ", error);
      });
  };

  useEffect(() => {
    getMiningAddress();
  }, []);

  const getTotalStake = () => {
    contract.read
      .stakeAmountTotal([id])
      .then((value) => {
        if (value) {
          settotalStake(value);
        }
      })
      .catch((error) => {
        console.error("total stake error: ", error);
      });
  };

  const getDelegators = () => {
    contract.read
      .poolDelegators([id])
      .then((value) => {
        if (value) {
          setDelegators(value);
        }
      })
      .catch((error) => {
        console.error("get delegators error: ", error);
      });
  };

  const getMyStakeAmount = () => {
    contract.read
      .stakeAmount([id, address])
      .then((value) => {
        if (value) {
          // console.log("my stake amt : ", value);
          setMyStakeAmt(value);
        }
      })
      .catch((error) => {
        console.error("total stake error: ", error);
      });
  };

  const getStakeFirstEpoch = () => {
    contract.read
      .stakeFirstEpoch([id, address])
      .then((value) => {
        if (value) {
          // console.log(" stake first : ", value);
          setStakeFirstEpoch(value);
        }
      })
      .catch((error) => {
        console.error("total stake error: ", error);
      });
  };

  const getMaxWithdrawOrderAllowed = () => {
    contract.read
      .maxWithdrawOrderAllowed([id, address])
      .then((value) => {
        if (value) {
          // console.log(" max withdraw order allowed : ", value);
          setmaxWithdrawOrderAllowed(value);
        }
      })
      .catch((error) => {
        console.error("total stake error: ", error);
      });
  };

  const getOrderedWithdrawAmount = () => {
    contract.read
      .orderedWithdrawAmount([id, address])
      .then((value) => {
        if (value) {
          // console.log("ordered withdraw amount : ", value);
          setOrderedWithdrawAmount(value);
        } else {
          // console.log("ordered withdraw no value");
        }
      })
      .catch((error) => {
        console.error("ordered withdraw amount: ", error);
      });
  };

  const getMaxWithdrawAllowed = () => {
    contract.read
      .maxWithdrawAllowed([id, address])
      .then((value) => {
        if (value) {
          // console.log("ordered withdraw amount : ", value);
          setMaxWithdrawAllowed(value);
        } else {
          // console.log("ordered withdraw no value");
        }
      })
      .catch((error) => {
        console.error("ordered withdraw amount: ", error);
      });
  };

  const getOrderWithdrawEpoch = () => {
    contract.read
      .orderWithdrawEpoch([id, address])
      .then((value) => {
        if (value) {
          // console.log("order withdraw epoch : ", value);
          setOrderWithdrawEpoch(value);
        } else {
          // console.log("order withdraw epoch no value");
        }
      })
      .catch((error) => {
        console.error("order withdraw epoch error: ", error);
      });
  };

  const orderWithdraw = async () => {
    // const weiAmt = Web3.utils.toWei(maxWithdrawOrderAllowed, "ether");

    setisLoading(true);

    // console.log("input poolId : ", id);
    // console.log("input amount : ", maxWithdrawOrderAllowed.toString());

    const hash = await contract.write
      .orderWithdraw([id, maxWithdrawOrderAllowed.toString()], {
        value: 0,
      })

      .catch((error) => {
        setisLoading(false);
        alert(error);
      });

    const tx = await publicClient.waitForTransactionReceipt({
      hash,
      confirmations: CONFIRMATIONS,
    });
    if (tx.status === "success") {
      setisLoading(false);
      Swal.fire({
        icon: "success",
        title: "Order Successful",
        text: "",
        footer: `<a href="https://${process.env.REACT_APP_EXPLORER_SUBDOMAIN}.lycanchain.com/tx/${hash}" target='_blank'>View Transaction</a>`,
      });
      handleUndelegateClose();
    } else {
      setisLoading(false);
      alert("error Transaction revert");
    }
  };

  const withdrawOrder = async () => {
    // console.log("input poolId : ", id);
    // console.log("input poolId : ", orderedWithdrawAmount);
    setisLoading(true);

    const hash = await contract.write
      .claimOrderedWithdraw([id], {
        value: 0,
      })
      .catch((error) => {
        setisLoading(false);
        alert(error);
      });

    const tx = await publicClient
      .waitForTransactionReceipt({
        hash,
        confirmations: CONFIRMATIONS,
      })
      .then((value) => {
        if (tx.status === "success") {
          setisLoading(false);
          Swal.fire({
            icon: "success",
            title: "Withdraw Successful",
            text: "",
            footer: `<a href="https://${process.env.REACT_APP_EXPLORER_SUBDOMAIN}.lycanchain.com/tx/${hash}" target='_blank'>View Transaction</a>`,
          });

          handleUndelegateClose();
        } else {
          setisLoading(false);
          alert("error Transaction revert");
        }
      })
      .catch((error) => {
        setisLoading(false);
        alert(error);
      });
  };

  const directWithdraw = async () => {
    setisLoading(true);

    // console.log("input poolId : ", id);
    // console.log("input amt : ", maxWithdrawAllowed);

    const hash = await contract.write
      .withdraw([id, maxWithdrawAllowed.toString()], {
        value: 0,
      })
      .catch((error) => {
        setisLoading(false);
        alert(error);
      });

    publicClient
      .waitForTransactionReceipt({
        hash,
        confirmations: CONFIRMATIONS,
      })
      .then((value) => {
        if (value.status === "success") {
          setisLoading(false);
          Swal.fire({
            icon: "success",
            title: "Withdraw Successful",
            text: "",
            footer: `<a href="https://${process.env.REACT_APP_EXPLORER_SUBDOMAIN}.lycanchain.com/tx/${hash}" target='_blank'>View Transaction</a>`,
          });

          handleUndelegateClose();
        } else {
          setisLoading(false);
          alert("error Transaction revert");
        }
      })
      .catch((error) => {
        setisLoading(false);
        alert(error);
      });
  };

  const getRewardAmount = async (epochs) => {
    // console.log("input epochs ", epochs);
    // console.log("input poolid ", id);
    // console.log("input epochs ", address);

    await contract.read
      .getRewardAmount([epochs, id, address])
      .then((value) => {
        if (value) {
          // console.log("reward amount : ", value);
          setrewardAmount(value);
        } else {
          console.log("reward amount no value");
        }
      })
      .catch((error) => {
        console.error("reward amount error: ", error);
      });
  };

  const getRewardWasTaken = async (currentEpoch) => {
    // console.log("input reward was taken : ", id);
    // console.log("input reward was taken : ", address);
    // console.log("input reward was taken : ", currentEpoch);

    if (currentEpoch) {
      await contract.read
        .getRewardAmount([id, address, currentEpoch.toString()])
        .then((value) => {
          if (value) {
            setRewardWasTaken(value);
            // console.log("reward was taken : ", rewardWasTaken);
          } else {
            console.log("reward was taken no value");
          }
        })
        .catch((error) => {
          console.error("reward was taken error: ", error);
        });
    }
  };

  const claimReward = async (epochs) => {
    setisLoading(true);
    // console.log("input epochs : ", epochs);
    // console.log("input poolId : ", id);

    const hash = await contract.write
      .claimReward([epochs, id], {
        value: 0,
      })
      .catch((error) => {
        setisLoading(false);
        alert(error);
      });

    publicClient
      .waitForTransactionReceipt({
        hash,
        confirmations: CONFIRMATIONS,
      })
      .then((value) => {
        if (value.status === "success") {
          Swal.fire({
            icon: "success",
            title: "Claim Successful",
            text: "",
            footer: `<a href="https://${process.env.REACT_APP_EXPLORER_SUBDOMAIN}.lycanchain.com/tx/${hash}" target='_blank'>View Transaction</a>`,
          });

          handleUndelegateClose();
        } else {
          alert("error Transaction revert");
        }
      })
      .catch((error) => {
        setisLoading(false);
        alert(error);
      });
  };

  const getRewardInfo = async () => {
    if (stakeFirstEpoch) {
      // console.log("reward stake first : ", stakeFirstEpoch);

      if (stakeFirstEpoch > 0) {
        const epochList = getEpochsBetween(
          parseInt(stakeFirstEpoch.toString()),
          parseInt(currentEpoch.toString())
        );
        epochList.pop();

        setcurrentEpochList(epochList);

        // console.log("epoch list : ", epochList);

        getRewardAmount(epochList);

        getRewardWasTaken(currentEpoch);

        // claimReward(epochList, poolId);
      } else {
        alert("No Reward Available");
      }
    } else {
      // console.log("reward not available");
    }
  };

  useEffect(() => {
    getRewardInfo();
  }, [stakeFirstEpoch, address]);

  const getPoolInfo = async () => {
    await validatorContract.read
      .poolName([id])
      .then((value) => {
        if (value) {
          setPoolName(value);
          console.log("poolName : ", value);
        } else {
          console.log("pool name no value");
        }
      })
      .catch((error) => {
        console.error("pool name error: ", error);
      });

    await validatorContract.read
      .poolDescription([id])
      .then((value) => {
        if (value) {
          setPoolDesc(value);
        } else {
          console.log("pool desc no value");
        }
      })
      .catch((error) => {
        console.error("pool desc error: ", error);
      });
  };

  useEffect(() => {
    if (id && address) {
      getPoolInfo();
      getTotalStake();
      getDelegators();
      getMyStakeAmount();
      getStakeFirstEpoch();
      getMaxWithdrawOrderAllowed();
      getOrderedWithdrawAmount();
      getMaxWithdrawAllowed();
      getOrderWithdrawEpoch();
      getDelegatorMinStake();
    }
    getPoolInfo();
  }, [show, showUndelegate]);

  const handleDelegate = () => {
    if (address) {
      setShow(true);
    } else {
      alert("Please connect wallet");
    }
  };

  const handleUndelegate = () => {
    if (address) {
      setShowUndelegate(true);
    } else {
      alert("Please connect wallet");
    }
  };

  useEffect(() => {
    if (data) {
      if (convertDividePrice(data.value)) {
        setcurrentBalance(convertDividePrice(data.value));
      } else {
        setcurrentBalance(0.0);
      }
    }
  }, [data]);

  return (
    <>
      <Header />
      <div className="layout">
        <div className="container">
          <div className="main">
            <div className="validator_card">
              <div className="user_details">
                <div className="d-flex align-items-center gap-3">
                  <img src="/assets/img/user/user3.png" alt="pfp" />
                  <div>
                    <div className="d-flex align-items-center gap-2">
                      <h3 className="user_name mb-0">
                        {poolName ? poolName : `Alpha ${id.slice(-2)}`}
                      </h3>{" "}
                      <span className="badge badge_green">Active</span>
                    </div>
                    <p className="text_pera mt-2">
                      {poolDesc
                        ? poolDesc
                        : "This validator is a member of the initial validator group."}
                    </p>
                    {rewardAmount && (
                      <p className="text-white">
                        Reward Amount: {convertDividePrice(rewardAmount)}
                      </p>
                    )}
                  </div>
                </div>
                <div className="">
                  <button
                    onClick={handleDelegate}
                    className="btn table_btn btn_brand me-2"
                  >
                    Delegate
                  </button>
                  {myStakeAmt || orderedWithdrawAmount > 0.0 ? (
                    <button
                      onClick={handleUndelegate}
                      className="btn table_btn btn_brand"
                    >
                      Withdraw
                    </button>
                  ) : null}
                  {rewardAmount > 0.0 ? (
                    <button
                      onClick={() => claimReward(currentEpochList)}
                      className="btn table_btn btn_brand ms-2"
                    >
                      Claim Reward
                    </button>
                  ) : null}
                </div>
              </div>
              <div className="row g-2 mt_30">
                <div className="col-xl-4 col-lg-5 mt-0">
                  <div className="payment_card mb_10 bg_main p-3">
                    <div className="d-flex flex-column justify-content-between gap-3 py-2">
                      <p className="card_title">Total LYC Staked</p>
                      <h5 className="card_price">
                        {convertDividePrice(totalStake)} LYC
                      </h5>
                    </div>
                    <div className="icon_card bg_dark_300">
                      <Staked />
                    </div>
                  </div>
                  <div className="payment_card bg_main mb_10 p-3">
                    <div className="d-flex flex-column justify-content-between gap-3 py-2">
                      <p className="card_title">Delegators</p>
                      <h5 className="card_price">{delegators.length}</h5>
                    </div>
                    <div className="icon_card bg_dark_300">
                      <Validators />
                    </div>
                  </div>
                  <div className="payment_card bg_main mb-lg-0 mb_10 p-3">
                    <div className="d-flex flex-column justify-content-between gap-3 py-2">
                      <p className="card_title">APY</p>
                      <h5 className="card_price">Up to 2.50%</h5>
                    </div>
                    <div className="icon_card bg_dark_300">
                      <APY />
                    </div>
                  </div>
                </div>
                <div className="col-xl-8 col-lg-7 mt-0">
                  <div className="details_card bg_main">
                    <div className="d_flex mb-md-3">
                      <p className="u_data gray">Operation Since</p>
                      <p className="u_data light_gray">2024 - 04 - 18</p>
                    </div>
                    <div className="d_flex mb-md-3">
                      <p className="u_data gray">Type</p>
                      <span className="position bg_dark_200">Cabinet</span>
                    </div>
                    <div className="d_flex mb-md-3">
                      <p className="u_data gray">Website</p>
                      <p className="u_data light_gray">-</p>
                    </div>
                    <div className="d_flex mb-md-3">
                      <p className="u_data gray">Self Delegation</p>
                      <p className="u_data light_gray">
                        {myStakeAmt ? convertDividePrice(myStakeAmt) : "0"} LYC
                      </p>
                    </div>
                    <div className="d_flex mb-md-3">
                      <p className="u_data gray">Delegators</p>
                      <p className="u_data light_gray">{delegators.length}</p>
                    </div>
                    <div className="d_flex mb-md-3">
                      <p className="u_data gray">Operator Address</p>
                      <a href="#" className="u_data text_brand">
                        {shortenAddress(miningAddress)}{" "}
                        <span className="ms-1">
                          <CopyIcon />
                        </span>
                      </a>
                    </div>
                    <div className="d_flex mb-md-3">
                      <p className="u_data gray">Consensus Address</p>
                      <a href="#" className="u_data text_brand">
                        {shortenAddress(miningAddress)}{" "}
                        <span className="ms-1">
                          <CopyIcon />
                        </span>
                      </a>
                    </div>
                    <div className="d_flex mb-md-3">
                      <p className="u_data gray">Vote Address</p>
                      <p className="u_data light_gray d-inline-block">
                        {shortenAddress(id)}{" "}
                        <span className="ms-1">
                          <CopyIcon />
                        </span>
                      </p>
                    </div>
                    <div className="d_flex">
                      <p className="u_data gray">Identity</p>
                      <p className="u_data light_gray d-inline-block">
                        {shortenAddress(id)}{" "}
                        <span className="ms-1">
                          <CopyIcon />
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-4">
              <h2 className="second_title">Delegator History</h2>
              <div className="table-responsive">
                <table className="table validator_table border-0 mb-0">
                  <thead>
                    <tr>
                      <th className="ps-3">
                        Address
                        <span className="ms-1">
                          <img src="/assets/img/icon/alert.svg" />
                        </span>
                      </th>
                      <th>
                        Type
                        <span className="ms-1">
                          <img src="/assets/img/icon/alert.svg" />
                        </span>
                      </th>
                      <th>
                        Amount
                        <span className="ms-1">
                          <img src="/assets/img/icon/alert.svg" />
                        </span>
                      </th>
                      <th>
                        Time
                        <span className="ms-1">
                          <img src="/assets/img/icon/alert.svg" />
                        </span>
                      </th>
                      <th className="text-end pe-3">
                        Txn Hash
                        <span className="ms-1">
                          <img src="/assets/img/icon/alert.svg" />
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {delegators.map((item, i) => (
                      <DelegatorItem i={i + 1} address={item} />
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="table_footer">
                <div className="d-flex flex-md-row flex-column align-items-md-center gap-2">
                  <p className="footer_title">Row per Page</p>
                  <select
                    className="form-select page_select"
                    value={itemsPerPage}
                    onChange={(e) => {
                      // console.log(e.target.value);
                      setitemsPerPage(e.target.value);
                    }}
                  >
                    <option value={10}>10</option>
                    <option value={15}>15</option>
                    <option value={20}>20</option>
                  </select>
                </div>
                <div className="d-flex flex-md-row flex-column align-items-md-center gap-2">
                  <p className="footer_title">
                    Total of {delegators.length} Validators
                  </p>
                  <ul className="table_pagination">
                    <li onClick={handlePrev}>
                      <Link>
                        <LeftArrow />
                      </Link>
                    </li>
                    {Array.from({ length: totalPages }, (_, index) => (
                      <li onClick={() => setcurrentPage(index + 1)}>
                        <Link className="">{index + 1}</Link>
                      </li>
                    ))}

                    <li onClick={handleNext}>
                      <Link>
                        <RightArrow />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <Delegate
                show={show}
                handleClose={handleClose}
                minStake={delegatorMinStake}
                balance={currentBalance}
                selectedPool={id}
                stakingAllowed={stakingAllowed}
              />
              <Undelegate
                show={showUndelegate}
                validator={id}
                balance={currentBalance}
                handleClose={handleUndelegateClose}
                maxWithdrawOrderAllowed={maxWithdrawOrderAllowed}
                orderedWithdrawAmount={orderedWithdrawAmount}
                maxWithdrawAllowed={maxWithdrawAllowed}
                stakeFirstEpoch={stakeFirstEpoch}
                orderWithdrawEpoch={orderWithdrawEpoch}
                onOrderWithdraw={orderWithdraw}
                onWithdrawOrder={withdrawOrder}
                onDirectWithdraw={directWithdraw}
                isLoading={isLoading}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const DelegatorItem = ({ address }) => {
  return (
    <tr>
      <td>
        <div className="table_data">
          <Link to={""} className="user_data text_brand">
            {shortenAddress(address)}
          </Link>
          <span className="cursor_pointer">
            <CopyIcon />
          </span>
        </div>
      </td>
      <td>Delegate</td>
      <td>
        <div className="d-flex gap-2 align-items-center">
          <span className="user_data">NA</span>
          <span className="user_data">LYC</span>
        </div>
      </td>
      <td>2024-07-22 10:52:27</td>
      <td>
        <Link to={""} className="user_data text_brand">
          0x969f...c805
        </Link>
      </td>
    </tr>
  );
};
