import React, { useEffect, useState } from "react";
import { ArrowGreen, ArrowLine, ArrowRight } from "./icons";
import { Moon } from "lunarphase-js";
import { useEpochDetails } from "../hooks/useStakingContract";

const MoonPhases = () => {
  let { nextEpoch } = useEpochDetails();
  const phase = Moon.lunarPhase();

  const [currentEpoch, setcurrentEpoch] = useState("New Moon");

  const [nextPhase, setnextPhase] = useState("");

  const [nextEpochPhase, setnextEpochPhase] = useState(null);

  const phases = [
    {
      id: "New",
      title: "New Moon",
      img: "/assets/img/moons/moon1.png",
      main: true,
    },
    {
      id: "Waxing Crescent",
      title: "Waxing Crescent",
      img: "/assets/img/moons/moon2.png",
      main: false,
    },
    {
      id: "First Quarter",
      title: "First Quarter",
      img: "/assets/img/moons/moon3.png",
      main: true,
    },

    {
      id: "Waxing Gibbous",
      title: "Waxing Gibbous",
      img: "/assets/img/moons/moon4.png",
      main: false,
    },

    {
      id: "Full",
      title: "Full Moon",
      img: "/assets/img/moons/moon5.png",
      main: true,
    },
    {
      id: "Waning Gibbous",
      title: "Waning Gibbous",
      img: "/assets/img/moons/moon6.png",
      main: false,
    },
    {
      id: "Last Quarter",
      title: "Last Quarter",
      img: "/assets/img/moons/moon7.png",
      main: true,
    },
    {
      id: "Waning Crescent",
      title: "Waning Crescent",
      img: "/assets/img/moons/moon8.png",
      main: false,
    },
  ];

  useEffect(() => {
    if (!phase) return;
    console.log("current phase : ", phase);

    switch (phase) {
      case "New":
        setcurrentEpoch("New");

        break;

      case "Waxing Crescent":
        setcurrentEpoch("New");
        break;

      case "First Quarter":
        setcurrentEpoch("First Quarter");
        break;

      case "Waxing Gibbous":
        setcurrentEpoch("First Quarter");
        break;

      case "Full":
        setcurrentEpoch("Full");
        break;

      case "Waning Gibbous":
        setcurrentEpoch("Full");
        break;

      case "Last Quarter":
        setcurrentEpoch("Last Quarter");
        break;

      case "Waning Crescent":
        setcurrentEpoch("Last Quarter");
        break;

      default: {
        setcurrentEpoch("New");
      }
    }

    const index = phases.findIndex((p) => p.id === currentEpoch);
    setnextPhase(phases[index + 1].id);
    if (currentEpoch === phases[6].id) {
      setnextEpochPhase(phases[0].id);
    } else {
      const nextPhaseIndex = phases.findIndex((p) => p.id === currentEpoch);
      setnextEpochPhase(phases[nextPhaseIndex + 2].id);
    }
  }, [phase, currentEpoch, nextEpochPhase]);

  return (
    <div className="d_grid gap-2">
      {phases.map((item, i) => (
        <div className="text-center" key={i}>
          <div>
            <img src={item.img} alt="Moon" />
          </div>
          <div className="text-center">
            <h5 className="new_moon mt-2 mb-1">{item.title}</h5>

            {currentEpoch === item.id && (
              <span className="badge badge_green">Current Epoch</span>
            )}
            {nextEpochPhase === item.id && (
              <span className="badge badge_yellow">Upcoming Epoch</span>
            )}
            {item.main &&
              currentEpoch !== item.id &&
              nextEpochPhase !== item.id && (
                <span className="badge badge_gray">Closed Epoch</span>
              )}

            {!item.main && nextPhase === item.id && (
              <div className="d-flex align-items-center justify-content-center gap-1">
                <span className="moon_status text_green">{nextEpoch}</span>
                <ArrowGreen />
              </div>
            )}
            {!item.main && nextPhase !== item.id && (
              <div className="d-flex align-items-center justify-content-center gap-1">
                <ArrowLine />
                <span className="moon_status gray">7 Day</span>
                <ArrowRight />
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default MoonPhases;

// {!item.main ? (
//   <div className="d-flex align-items-center justify-content-center gap-1">
//     <ArrowLine />
//     <span className="moon_status gray">7 Day</span>
//     <ArrowRight />
//   </div>
// ) : nextPhase === i ? (
//   <div className="d-flex align-items-center justify-content-center gap-1">
//     <span className="moon_status text_green">{nextEpoch}</span>
//     <ArrowGreen />
//   </div>
// ) : currentEpoch === item.id ? (
//   <span className="badge badge_green">Live Epoch</span>
// ) : (
//   <span className="badge badge_gray">Closed Epoch</span>
// )}

{
  /* <span className="badge badge_gray">Closed Epoch</span> */
}
{
  /* <span className="badge badge_gray">Closed Epoch</span> */
}
{
  /* <span className="moon_status gray">Completed</span> */
}
{
  /* <span className="badge badge_green">Live Epoch</span> */
}

{
  /* <div className="d-flex align-items-center justify-content-center gap-1">
            <span className="moon_status text_green">4d : 12H : 34M : 43S</span>
            <ArrowGreen />
          </div> */
}

{
  /* <div className="text-center">
        <div>
          <img src="/assets/img/moons/moon2.png" alt="Moon" />
        </div>
        <div className="text-center">
          <h5 className="crescent_moon mt-2 mb-1">Waxing Crescent</h5>
          {phase === "Waxing Crescent" ? (
            <span className="badge badge_green">Live Epoch</span>
          ) : (
            <span className="badge badge_gray">Closed Epoch</span>
          )}
        </div>
      </div>
      <div className="text-center">
        <div>
          <img src="/assets/img/moons/moon3.png" alt="Moon" />
        </div>
        <div className="text-center">
          <h5 className="new_moon mt-2 mb-1">First Quarter</h5>
          {phase === "First Quarter" ? (
            <span className="badge badge_green">Live Epoch</span>
          ) : (
            <span className="badge badge_gray">Closed Epoch</span>
          )}
        </div>
      </div>
      <div className="text-center">
        <div>
          <img src="/assets/img/moons/moon4.png" alt="Moon" />
        </div>
        <div className="text-center">
          <h5 className="crescent_moon mt-2 mb-1">Waxing Gibbous</h5>
          {phase === "Waxing Gibbous" ? (
            <span className="badge badge_green">Live Epoch</span>
          ) : (
            <span className="badge badge_gray">Closed Epoch</span>
          )}
        </div>
      </div>
      <div className="text-center">
        <div>
          <img src="/assets/img/moons/moon5.png" alt="Moon" />
        </div>
        <div className="text-center">
          <h5 className="new_moon mt-2 mb-1">Full Moon</h5>
          {phase === "Full" ? (
            <span className="badge badge_green">Live Epoch</span>
          ) : (
            <span className="badge badge_gray">Closed Epoch</span>
          )}
        </div>
      </div>
      <div className="text-center">
        <div>
          <img src="/assets/img/moons/moon6.png" alt="Moon" />
        </div>
        <div className="text-center">
          <h5 className="crescent_moon mt-2 mb-1">Waning Gibbous</h5>
          {phase === "Waning Gibbous" ? (
            <span className="badge badge_green">Live Epoch</span>
          ) : (
            <span className="badge badge_gray">Closed Epoch</span>
          )}
        </div>
      </div>
      <div className="text-center">
        <div>
          <img src="/assets/img/moons/moon7.png" alt="Moon" />
        </div>
        <div className="text-center">
          <h5 className="new_moon mt-2 mb-1">Third Quarter</h5>
          {phase === "Last Quarter" ? (
            <span className="badge badge_green">Live Epoch</span>
          ) : (
            <span className="badge badge_gray">Closed Epoch</span>
          )}
        </div>
      </div>
      <div className="text-center">
        <div>
          <img src="/assets/img/moons/moon8.png" alt="Moon" />
        </div>
        <div className="text-center">
          <h5 className="crescent_moon mt-2 mb-1">Waning Crescent</h5>
          {phase === "Waning Crescent" ? (
            <span className="badge badge_green">Live Epoch</span>
          ) : (
            <span className="badge badge_gray">Closed Epoch</span>
          )}
        </div>
      </div> */
}
