import { Link } from "react-router-dom";

import { useWeb3Modal } from "@web3modal/wagmi/react";
import { useAccount } from "wagmi";

export default function Header() {
  const { address, isConnecting, isDisconnected } = useAccount();

  const { open, close } = useWeb3Modal();

  return (
    <>
      {/* <div className="collapse" id="navbarToggleExternalContent">
        <div className="bg-dark p-4">
          <h5 className="text-white h4">Collapsed content</h5>
          <span className="text-muted">Toggleable via the navbar brand.</span>
        </div>
      </div> */}
      <header>
        <nav className="navbar p-0">
          <div className="main_menu">
            <Link to={"/"}>
              <img src="/assets/img/logo.svg" />
            </Link>
            <div>
              {address ? (
                <w3m-button balance="show" />
              ) : (
                <button onClick={open} className="btn wallet_btn">
                  Connect Wallet
                </button>
              )}
            </div>
          </div>
          {/* <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarToggleExternalContent"
            aria-controls="navbarToggleExternalContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button> */}
        </nav>
      </header>
    </>
  );
}
