export const shortenAddress = (address) => {
  if (address && typeof address === "string") {
    return `${address?.substring(0, 6)}...${address?.substring(
      address?.length - 6
    )}`;
  } else {
    return "-";
  }
};

export function isValidURL(url) {
  try {
    new URL(url);
    return true;
  } catch (e) {
    return false;
  }
}

export function hexToDecimal(hexString) {
  // Ensure the string is properly formatted by removing leading "0x" if present
  if (hexString.startsWith("0x")) {
    hexString = hexString.slice(2);
  }

  // Convert the hexadecimal string to a decimal number
  return parseInt(hexString, 16);
}

export function isStringInArray(stringToFind, arrayList) {
  return arrayList.includes(stringToFind);
}

export function formatTime(seconds) {
  const days = Math.floor(seconds / (24 * 3600));
  seconds %= 24 * 3600;
  const hours = Math.floor(seconds / 3600);
  seconds %= 3600;
  const minutes = Math.floor(seconds / 60);
  seconds %= 60;

  const pad = (num) => String(num).padStart(2, "0");

  return `${pad(days)} D : ${pad(hours)} H : ${pad(minutes)} M : ${pad(
    seconds
  )} S`;
}
