import { webSocket } from "viem";
import {
  BLOCK_EXPLORER,
  CHAIN_ID,
  CHAIN_NAME,
  RPC,
  RPC_URLS_DEFAULT,
  RPC_URLS_WEBSOCKET,
  SYMBOL,
} from "../../constants";

export const lycanChainBeta = {
  id: CHAIN_ID,
  name: CHAIN_NAME,
  network: CHAIN_NAME,
  nativeCurrency: {
    decimals: 18,
    name: CHAIN_NAME,
    symbol: SYMBOL,
  },
  rpcUrls: {
    public: { http: [RPC] },
    default: { http: [RPC] },
  },
  blockExplorers: {
    // default: { name: '', url: 'https://bstscan.com' },
    // etherscan: { name: '', url: 'https://bstscan.com' },
  },
  testnet: false,
};

export const lycanChain = {
  id: 721,
  name: process.env.REACT_APP_CHAIN_NAME,
  network: process.env.REACT_APP_CHAIN_NAME,
  nativeCurrency: {
    decimals: 18,
    name: process.env.REACT_APP_CHAIN_NAME,
    symbol: process.env.REACT_APP_SYMBOL,
  },
  rpcUrls: {
    public: { http: [process.env.REACT_APP_RPC] },
    default: { http: [process.env.REACT_APP_RPC] },
  },
  blockExplorers: {
    default: BLOCK_EXPLORER,
  },
  testnet: false,
};

export const lycanChainMain = {
  id: 721,
  name: "Lycan Chain",
  network: "Lycan Chain",
  nativeCurrency: {
    decimals: 18,
    name: "LYC",
    symbol: "LYC",
  },
  rpcUrls: {
    public: { http: ["https://rpc.lycanchain.com"] },
    default: { http: ["https://rpc.lycanchain.com"] },
  },
  blockExplorers: {
    // default: { name: '', url: 'https://bstscan.com' },
    // etherscan: { name: '', url: 'https://bstscan.com' },
  },
  testnet: false,
};
